import React, { useState, useEffect, useRef } from "react";
import ChessboardCustom from "../components/ChessboardCustom";
import { renderMoveHistory } from "../Integrations/MoveHistoryTable";
import { Chess } from "chess.js";
import EngineMoves from "../Integrations/EngineMoves";
import { drawEngineArrow, handleSidelineMove, highlightLastMove, onSquareClick, onDrop, renderCapturedPieces, handleTakeBackMove, handleSidelineSquareClick, onDropWithSidelines } from "../Integrations/helperFunctions";
import PromotionModal from '../Integrations/PromotionModal';
import ShareModal from "../Integrations/ShareModal";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import AIContainer from "../Integrations/AIContainer";
import AIHelpBox from "../Integrations/AIHelpBox";

const Analyze = () => {
  const [game, setGame] = useState(new Chess());
  const [displayFen, setDisplayFen] = useState('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
  const [initialFen, setInitialFen] = useState('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
  const [orientation, setOrientation] = useState("White");
  const [squareStyles, setSquareStyles] = useState({});
  const [selectedMoveIndex, setSelectedMoveIndex] = useState(-1);
  const canvasRef = useRef(null);
  const restoreSquareStyles = useRef({});
  const [moveData, setMoveData] = useState([]);
  const [chessboardSize, setChessboardSize] = useState(null);
  const [pieceSquare, setPieceSquare] = useState('');
  const [promotionOpen, setPromotionOpen] = useState(false);
  const [promotionDetails, setPromotionDetails] = useState(null);
  const [playerColor, setPlayerColor] = useState("White");
  const [savedStyles, setSavedStyles] = useState({});
  const moveHistoryRef = useRef(null);
  const sidelineRef = useRef(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [sidelines, setSidelines] = useState([]);
  const moveHistory = game.history({ verbose: true }); // Generate move history from the game state
  const sidelinesAllowed = true;
  const [selectedSidelineIndex, setSelectedSidelineIndex] = useState(null);
  const [selectedSidelineMoveIndex, setSelectedSidelineMoveIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { fenFromElse, orientationFromElse, returnPath, currentPageState } = location.state || {};
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
//  const [currentPageState, setCurrentPageState] = useState(null);

  useEffect(() => {
      const fenParam = searchParams.get("fen");
      const orientationParam = searchParams.get("orientation");

      if (fenParam) {
        const decodedFen = decodeURIComponent(fenParam);
        setDisplayFen(decodedFen);
        setInitialFen(decodedFen)
        game.load(decodedFen); // Load the decoded FEN

        // Capitalize the first letter of the orientation parameter
        const capitalizedOrientation =
          orientationParam?.charAt(0).toUpperCase() + orientationParam?.slice(1);

        setOrientation(capitalizedOrientation || "White"); // Default to "White" if undefined
      }
    }, [searchParams, game]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (fenFromElse) {
      setDisplayFen(fenFromElse);
      setInitialFen(fenFromElse);
      setOrientation(orientationFromElse);
      setPlayerColor(orientationFromElse);
      game.load(fenFromElse); // Load the FEN
    }
  }, [fenFromElse, orientationFromElse]);

  const goBack = () => {
    if (returnPath) {

      console.log("Current page state on return " + JSON.stringify(currentPageState));
      navigate(returnPath, {
        state: currentPageState, // Pass updated state back
    });
    }
  };

useEffect(() => {
  const fenParam = searchParams.get("fen");
  const orientationParam = searchParams.get("orientation");

  if (fenParam) {
    setDisplayFen(fenParam);

    // Capitalize the first letter of the orientation parameter
    const capitalizedOrientation =
      orientationParam?.charAt(0).toUpperCase() + orientationParam?.slice(1);

    setOrientation(capitalizedOrientation || "White"); // Default to "White" if undefined
    game.load(fenParam); // Load the FEN into your chess logic
  }
}, [searchParams, game]);


  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const copyToClipboard = (text, message) => {
    navigator.clipboard.writeText(text).then(() => {
      alert(`${message} copied to clipboard!`);
    }).catch((err) => {
      console.error("Failed to copy text:", err);
    });
  };

  // Scroll to the selected move
  const scrollToSelectedMove = () => {
    if (moveHistoryRef.current) {
      const selectedElement = moveHistoryRef.current.querySelector(".selected");
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

const scrollToSelectedSideline = () => {
  if (sidelineRef.current) {
    const selectedMove = sidelineRef.current.querySelector(".selected");
    if (selectedMove) {
      selectedMove.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
};

  // Trigger scrolling whenever the selected move index changes
  useEffect(() => {
    scrollToSelectedMove();
  }, [selectedMoveIndex]);

    // Trigger scrolling whenever the selected move index changes
  useEffect(() => {
    scrollToSelectedSideline();
  }, [selectedSidelineMoveIndex]);

const handlePromotion = (piece) => {
  console.log('Handling promotion...');
  if (promotionDetails) {
    const { pieceSquare, square } = promotionDetails;

    // Temporary game to manage the FEN and moves
    const tempGame = new Chess();
    tempGame.load(displayFen);

    const move = tempGame.move({ from: pieceSquare, to: square, promotion: piece });

    if (!move) {
      console.error("Promotion move could not be applied.");
      return;
    }

    // Check if in a sideline
    if (selectedSidelineIndex !== null && selectedSidelineMoveIndex !== null) {
      console.log("Applying promotion in sideline...");

      handleSidelineMove({
        move,
        tempGame,
        displayFen,
        selectedMoveIndex,
        selectedSidelineIndex,
        selectedSidelineMoveIndex,
        setSelectedSidelineIndex,
        setSelectedSidelineMoveIndex,
        setSidelines,
      });
    } else {
      console.log("Applying promotion in mainline...");
      handleMove(pieceSquare, square, piece);
    }

    // Update state after promotion
    setPromotionOpen(false);
    setPromotionDetails(null);
    setDisplayFen(tempGame.fen());
    console.log("Promotion handled successfully.");
  }
};


  useEffect(() => {
    highlightLastMove(selectedMoveIndex, game, setSquareStyles);
  }, [selectedMoveIndex, displayFen]);

useEffect(() => {
  console.log("Running sideline highlight effect...");
  console.log("Selected Sideline Index:", selectedSidelineIndex);
  console.log("Selected Sideline Move Index:", selectedSidelineMoveIndex);

  if (
    selectedSidelineIndex !== null &&
    selectedSidelineMoveIndex !== null &&
    sidelines[selectedMoveIndex]?.[selectedSidelineIndex]
  ) {
    const sideline = sidelines[selectedMoveIndex][selectedSidelineIndex];
    const tempGame = new Chess(sideline.initialFen); // Start from the initial FEN of the sideline

    sideline.moves.slice(0, selectedSidelineMoveIndex + 1).forEach((move, idx) => {
      tempGame.move(move);
      console.log(`Sideline move ${idx + 1}: ${move}`);
    });

    // Call the existing highlightLastMove function
    highlightLastMove(selectedSidelineMoveIndex, tempGame, setSquareStyles);
  }
}, [selectedSidelineIndex, selectedSidelineMoveIndex, game, sidelines]);

useEffect(() => {
    setupCanvas();
}, [chessboardSize]);

const setupCanvas = () => {
  const canvas = canvasRef.current;

  // Using the latest chessboard size passed from the parent component
  const size = chessboardSize;

  if (canvas) {
    canvas.width = size; // Directly manipulating canvas size
    canvas.height = size;
    canvas.style.width = `${size}px`; // Adjusting style for visual size
    canvas.style.height = `${size}px`;

    // Accessing the canvas context to draw or clear it
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas for new drawing
    ctx.fillStyle = 'rgba(50, 50, 50, 0)'; // Setting a fill style for demonstration
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Filling the canvas with the chosen style
  } else {
//    console.log('Canvas not found');
  }
};

  useEffect(() => {
    setDisplayFen(game.fen());
  }, [game]);

  const flipBoard = () => {
    setOrientation((prev) => (prev === "White" ? "Black" : "White"));
  };

  const handleMoveData = (data) => {
    setMoveData(data); // Store the move data in state
  };

const drawArrows = (moves) => {
  const canvas = canvasRef.current;
  if (!canvas) {
    console.error("Canvas not found");
    return;
  }

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    console.error("Canvas context not available");
    return;
  }

  // Clear the entire canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Helper function to reverse square names for black orientation
  const reverseSquare = (square) => {
    if (!square || square.length !== 2) return square; // Invalid square, return as is
    const files = "abcdefgh";
    const ranks = "12345678";
    const reversedFile = files[7 - files.indexOf(square[0])];
    const reversedRank = ranks[7 - ranks.indexOf(square[1])];
    return reversedFile + reversedRank;
  };

  // Draw each arrow in the moves array
  moves.forEach((move) => {
    if (move?.from && move?.to && move?.color) {
      const fromSquare = orientation === "Black" ? reverseSquare(move.from) : move.from;
      const toSquare = orientation === "Black" ? reverseSquare(move.to) : move.to;

      drawEngineArrow(canvasRef, fromSquare, toSquare, move.color);
    }
  });
};

  // React to moveData changes to draw arrows
  useEffect(() => {
    drawArrows(moveData); // Process and draw arrows when moveData updates
  }, [moveData, orientation]);

const handleMove = (from, to, piece=null) => {

  const move = game.move({ from, to, promotion: piece });

  if (move) {
    // Update display and states if the move is valid
    setDisplayFen(game.fen());
    setSelectedMoveIndex((prevIndex) => prevIndex + 1);
    setSquareStyles({}); // Reset square styles
    setPieceSquare(""); // Clear the selected square
  } else {
//    alert("Invalid move!"); // Handle invalid moves gracefully
  }
};

const handleTakeBack = () => {
  // Take back the move from the main game
  handleTakeBackMove(
    game,                   // Current game instance
    selectedMoveIndex,      // Current selected move index
    initialFen,             // Initial FEN of the game
    setGame,                // Function to update the game state
    setSelectedMoveIndex,   // Function to update selected move index
    setDisplayFen,          // Function to update the displayed FEN
    setSquareStyles         // Function to update square styles (optional)
  );

  // Update the sidelines to match the new selected move index
  setSidelines((prevSidelines) => {
    const updatedSidelines = [...prevSidelines];

    // If the new selected move index is invalid, reset sidelines for that index
    if (selectedMoveIndex === -1) {
      console.log("Resetting sidelines because no moves are left.");
      updatedSidelines[selectedMoveIndex] = [];
    } else {
      // Otherwise, retain only valid sidelines up to the selected index
      console.log(`Updating sidelines to match new move index: ${selectedMoveIndex}`);
      updatedSidelines[selectedMoveIndex] = updatedSidelines[selectedMoveIndex]?.slice(0, selectedSidelineMoveIndex) || [];
    }

    console.log("Updated sidelines after take back:", JSON.stringify(updatedSidelines));
    return updatedSidelines;
  });

  // Reset selected sideline index and move index
  setSelectedSidelineIndex(null);
  setSelectedSidelineMoveIndex(null);
};

const isOnLastMove = selectedMoveIndex === game.history().length - 1;

const onSquareClickHandler = isOnLastMove
  ? (square) =>
      onSquareClick(
        square,
        setPromotionOpen,
        promotionDetails,
        setPromotionDetails,
        game,
        pieceSquare,
        setPieceSquare,
        restoreSquareStyles,
        squareStyles,
        setSquareStyles,
        handleMove,
        selectedMoveIndex
      )
  : (square) =>
      handleSidelineSquareClick(
          square,
          setPromotionOpen,
          promotionDetails,
          setPromotionDetails,
          displayFen,
          setDisplayFen,
          pieceSquare,
          setPieceSquare,
          restoreSquareStyles,
          squareStyles,
          setSquareStyles,
          setSidelines,
          selectedSidelineIndex,
          setSelectedSidelineIndex,
          selectedSidelineMoveIndex,
          setSelectedSidelineMoveIndex,
          selectedMoveIndex,
          sidelines
            );

const onDropHandler = isOnLastMove
  ? ( sourceData ) => {
      const { sourceSquare, targetSquare } = sourceData;
      return onDrop({
        sourceSquare,
        targetSquare,
        game,
        setPromotionDetails,
        setPromotionOpen,
        handleMove,
        selectedMoveIndex,
      });
    }
  : ( sourceData ) => {
      const { sourceSquare, targetSquare } = sourceData;
      return onDropWithSidelines({
        sourceSquare,
        targetSquare,
        game,
        setPromotionDetails,
        setPromotionOpen,
        handleMove,
        displayFen,
        setDisplayFen,
        selectedMoveIndex,
        selectedSidelineIndex,
        setSelectedSidelineIndex,
        selectedSidelineMoveIndex,
        setSelectedSidelineMoveIndex,
        setSidelines,
        sidelines,
      });
    };

const customLabels = {
  gameanalysis: 'Game Analysis',
  puzzles: 'Puzzles',
  calculationpuzzles: 'Calculation Practice',
  blindfoldpuzzles: 'Blindfold Puzzles',
  endgames: 'Endgames',
};

  return (
    <div className="analyze-container">
        {returnPath && (
          <div className='return-container'>
            <button className='return-button' onClick={goBack}>
              {`← Return to ${
                customLabels[returnPath.replace(/^\//, '')] || returnPath
                  .replace(/-/g, ' ')
                  .split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')
              }`}
            </button>
          </div>
        )}
        {promotionOpen && (
          <div className='promotion-modal-container'>
            <PromotionModal
              onPromote={handlePromotion}
              pieces={['q', 'r', 'n', 'b']}
              color={playerColor === 'White' ? 'w' : 'b'}
            />
          </div>
        )}
      <ShareModal
        isOpen={isShareModalOpen}
        closeModal={closeShareModal}
        fen={displayFen}
        pgn={game.pgn()}
        copyToClipboard={copyToClipboard}
      />
      {(
      <div className="game-layout">
        <div className="chessboard-container">
        <div className="captures-container-absolute">
          {renderCapturedPieces(displayFen)}
        </div>


          <canvas ref={canvasRef} style={{ zIndex: 100, position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}></canvas>

          <ChessboardCustom
            position={displayFen}
            orientation={orientation}
            onSquareClick={onSquareClickHandler}
            onDrop={onDropHandler}
            updateChessboardSize={setChessboardSize}
            squareStyles={squareStyles}
            degreeOfBlindness='Normal'
          />
        </div>
        <div className="move-history-container">
        {!isSmallScreen && (
          <EngineMoves fen={displayFen} onMoveData={handleMoveData} game={game} />
         )}
          {renderMoveHistory(
            {
              state: {
                history: game.history({ verbose: true }),
                fen: game.fen(),
                selectedMoveIndex: selectedMoveIndex,
                isAnalyze: true,
                initialFen: initialFen,
              },
              setDisplayFen,
              setSquareStyles,
              setSelectedMoveIndex,
              flipBoard,
              moveHistoryRef,
              sidelineRef,
              handleTakeBack,
              openShareModal,
              setSidelines,
              selectedSidelineIndex,
              setSelectedSidelineIndex,
              selectedSidelineMoveIndex,
              setSelectedSidelineMoveIndex
            },
            game.history({ verbose: true }),
            (data) => data,
            sidelines,
            true
          )}
        </div>
         {isSmallScreen && (
            <div className="engine-mobile-container">
              <EngineMoves fen={displayFen} onMoveData={handleMoveData} game={game} />
            </div>
         )}
      </div>
      )}
    </div>
  );
};

export default Analyze;
