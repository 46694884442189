import React, { useState, useEffect, useRef } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import FileStructure from '../Integrations/FileStructure';
import SaveLineModal from '../Integrations/SaveLineModal';
import '../styles/moveTrainer.css';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';
import { renderMoveHistory } from '../Integrations/MoveHistoryTable';
import PromotionModal from '../Integrations/PromotionModal';
import { moveTrainerRide, moveTrainerRide2 } from '../Integrations/Joyrides';
import JoyrideWrapper from '../Integrations/JoyrideWrapper'; // Import the JoyrideWrapper
import { db } from '../firebase-config';
import { useLocation } from 'react-router-dom';
import EngineMoves from "../Integrations/EngineMoves";
import { drawEngineArrow } from "../Integrations/helperFunctions";

const DEFAULT_START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const MoveTrainer = () => {
  const [chessboardSize, setChessboardSize] = useState(600);
  const [game, setGame] = useState(new Chess(DEFAULT_START_FEN)); // Initialize game with start FEN
  const [userColor, setUserColor] = useState('white'); // Let user choose which side to play
  const [correctMoves, setCorrectMoves] = useState([]); // Store the correct move sequence (used in training mode)
  const [moveIndex, setMoveIndex] = useState(0); // Track user's progress through the move sequence
  const [squareStyles, setSquareStyles] = useState({}); // Store styles for flashing feedback
  const [isFinished, setIsFinished] = useState(false); // Track whether training is complete
  const [percentageCorrect, setPercentageCorrect] = useState(0); // Track correctness percentage
  const [moves, setMoves] = useState([]); // Record user's moves (used in add-line mode)
  const [showSaveModal, setShowSaveModal] = useState(false); // Control visibility of SaveLineModal
  const { user } = useUser(); // Get the current user
  const [directories, setDirectories] = useState([]); // Store user's directories
  const [mode, setMode] = useState('train'); // Track whether we're in add-line or train mode
  const moveHistoryRef = useRef(null);
  const [selectedMoveIndex, setSelectedMoveIndex] = useState(-1);
  const [initialNote, setInitialNote] = useState('');
  const [currentNote, setCurrentNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [displayFen, setDisplayFen] = useState(DEFAULT_START_FEN);
  const [pieceSquare, setPieceSquare] = useState(null)
  const [promotionOpen, setPromotionOpen] = useState(false);
  const [promotionDetails, setPromotionDetails] = useState(null);
  const [currentDirectory, setCurrentDirectory] = useState('');
  const previousMoveIndexRef = useRef(selectedMoveIndex);
  const [attemptsTracker, setAttemptsTracker] = useState([]); // New state for tracking correct first-time attempts
  const [selectedItem, setSelectedItem] = useState(null);
  const [shouldInitialize, setShouldInitialize] = useState(false);
  const [randomizedLines, setRandomizedLines] = useState([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [hintCount, setHintCount] = useState(0); // Track the number of hints for each move
  const [showAddLineModal, setShowAddLineModal] = useState(false);
  const [pgnInput, setPgnInput] = useState('');
  const [useManualEntry, setUseManualEntry] = useState(true);
  const [lineName, setLineName] = useState('');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [hasSeenOpeningTrainer, setHasSeenOpeningTrainer] = useState(false);
  const [isTourOpen2, setIsTourOpen2] = useState(false);
  const [hasSeenOpeningTrainer2, setHasSeenOpeningTrainer2] = useState(false);
  const [doingTours, setDoingTours] = useState(false);
  const [notifier, setNotifier] = useState(0);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [editLine, setEditLine] = useState(null); // Tracks the line being edited
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gameDataString = queryParams.get('gameData');
  const partialGameData = gameDataString ? JSON.parse(gameDataString) : null;
  const [moveData, setMoveData] = useState([]);
  const canvasRef = useRef(null);

useEffect(() => {
  if (partialGameData) {
    const newGame = new Chess();
    console.log('partial game data; ' + partialGameData);
    newGame.loadPgn(partialGameData);

    setGame(newGame);
    const lastMoveIndex = newGame.history().length - 1;
    setSelectedMoveIndex(lastMoveIndex);

    // Set displayFen to the final position
    setDisplayFen(newGame.fen());
    alert("Opening loaded successfully! Choose a folder and add line from current position to proceed.")
  }
}, [partialGameData]);

useEffect(() => {
  if (user) {
    setShowLoginMessage(false); // Hide the login message if the user is logged in
  } else {
    setShowLoginMessage(true);  // Show the login message if no user is logged in
  }
}, [user]);

const drawArrows = (moves) => {
  const canvas = canvasRef.current;
  if (!canvas) {
    console.error("Canvas not found");
    return;
  }

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    console.error("Canvas context not available");
    return;
  }

  // Clear the entire canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Helper function to reverse square names for black orientation
  const reverseSquare = (square) => {
    if (!square || square.length !== 2) return square; // Invalid square, return as is
    const files = "abcdefgh";
    const ranks = "12345678";
    const reversedFile = files[7 - files.indexOf(square[0])];
    const reversedRank = ranks[7 - ranks.indexOf(square[1])];
    return reversedFile + reversedRank;
  };

  // Draw each arrow in the moves array
  moves.forEach((move) => {
    if (move?.from && move?.to && move?.color) {
      const fromSquare = userColor === "black" ? reverseSquare(move.from) : move.from;
      const toSquare = userColor === "black" ? reverseSquare(move.to) : move.to;

      drawEngineArrow(canvasRef, fromSquare, toSquare, move.color);
    }
  });
};

const setupCanvas = () => {
  const canvas = canvasRef.current;

  if (!canvas) {
    console.error("Canvas not found");
    return;
  }

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    console.error("Canvas context not available");
    return;
  }

  // **Always clear the canvas before doing anything**
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // **If in train mode, stop execution after clearing**
  if (mode === "train") {
    return;
  }

  // **Otherwise, update canvas size and style**
  const size = chessboardSize;
  canvas.width = size;
  canvas.height = size;
  canvas.style.width = `${size}px`;
  canvas.style.height = `${size}px`;

  // Fill the canvas with a transparent background
  ctx.fillStyle = "rgba(0, 0, 0, 0)";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
};

useEffect(() => {
  setupCanvas(); // Run setupCanvas regardless of mode
}, [chessboardSize, mode]);


  // React to moveData changes to draw arrows
  useEffect(() => {
    if (mode !== 'train') {
      drawArrows(moveData);
    }
  }, [moveData, userColor, mode]);  

  const handleMoveData = (data) => {
    if (mode === 'train') return;  // Prevent engine data from being set
    setMoveData(data);
  };  

  const handleCloseLoginMessage = () => {
    setShowLoginMessage(false);
  };

  // Update isWideScreen on window resize
  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

//const handleSavePgn = async () => {
//  if (!lineName) {
//    alert("Filename is required.");
//    return;
//  }
//
//  if (!selectedItem || !selectedItem.path) {
//    alert("Please select a folder to save this PGN.");
//    return;
//  }
//
//  // Step 1: Extract moves and notes from the PGN
//  const movesWithNotes = extractMovesAndNotes(pgnInput);
//
//  if (!movesWithNotes.length) {
//    alert('Invalid PGN: No moves found. Please check your PGN and try again.');
//    return;
//  }
//
//  // Step 2: Validate moves using a temporary Chess instance
//  const tempGame = new Chess();
//  let isValid = true;
//
//  for (const move of movesWithNotes) {
//    const moveResult = tempGame.move(move.san);
//    if (!moveResult) {
//      console.error("Invalid move in PGN:", move.san);
//      alert(`Invalid move found: "${move.san}". Please check your PGN and try again.`);
//      isValid = false;
//      break;
//    }
//  }
//
//  // If any move was invalid, stop and return
//  if (!isValid) {
//    return;
//  }
//
//  // Step 3: Save the original PGN since all moves were valid
//  try {
//    // Fetch user data from Firestore
//    const docRef = doc(getFirestore(), 'users', user.uid);
//    const docSnap = await getDoc(docRef);
//    const data = docSnap.exists() ? docSnap.data() : {};
//    const updatedFiles = { ...data.MoveTrainer || {} };
//    let current = updatedFiles;
//
//    // Use the selected folder path to navigate to the correct folder
//    selectedItem.path.split('/').forEach(segment => {
//      if (!current[segment]) current[segment] = {};
//      current = current[segment];
//    });
//    if (!current.lines) current.lines = [];
//
//    // Add the new PGN with the custom filename
//    current.lines.push({ name: lineName, pgn: pgnInput });
//
//    // Save to Firestore
//    await setDoc(docRef, { MoveTrainer: updatedFiles }, { merge: true });
//    alert('PGN saved successfully.');
//
//    // Reset modal state
//    setShowAddLineModal(false);
//    setPgnInput('');
//    setLineName(''); // Clear line name
//    setNotifier((prevNotifier) => prevNotifier + 1);
//
//  } catch (error) {
//    console.error('Error saving PGN to Firestore:', error);
//    alert('An error occurred while saving the PGN. Please try again.');
//  }
//};
//
//// Function to parse the PGN and extract the moves and notes manually
//const extractMovesAndNotes = (pgn) => {
//  const newGame = new Chess();
//  console.log("Original PGN input:", pgn);
//
//  // Step 1: Remove all headers (content between [ and ])
//  const withoutHeaders = pgn.replace(/\[.*?\]/g, '');
//
//  // Step 2: Clean up the PGN input - Remove line breaks and unnecessary whitespace
//  const cleanedPgn = withoutHeaders
//    .replace(/\r?\n|\r/g, ' ')   // Replace all line breaks with spaces
//    .replace(/\s+/g, ' ')         // Replace multiple spaces with a single space
//    .trim();                      // Trim leading and trailing whitespace
//
//  console.log("Cleaned PGN:", cleanedPgn);
//
//  // Step 3: Extract moves and notes without interpreting move numbers as moves
//  const moveRegex = /(?:\d+\.+\s*)?([a-h1-8RNBQKx#+=O\-]+)\s*({[^}]*})?/g; // Updated to properly ignore move numbers
//  let match;
//  const movesWithNotes = [];
//
//  while ((match = moveRegex.exec(cleanedPgn)) !== null) {
//    const san = match[1]; // Extract the SAN (Standard Algebraic Notation) move
//    const comment = match[2] ? match[2].replace(/[{}]/g, '').trim() : ''; // Extract the comment if present
//    movesWithNotes.push({ san, note: comment });
//  }
//
//  console.log("Moves with Notes Extracted:", movesWithNotes);
//  return movesWithNotes;
//};

const handleModeChange = (newMode) => {
  setMode((prevMode) => (prevMode !== newMode ? newMode : prevMode));
};

const handleSavePgn = async () => {
  if (!lineName) {
    alert("Filename is required.");
    return;
  }

  if (!selectedItem || !selectedItem.path) {
    alert("Please select a folder to save this PGN.");
    return;
  }

  // Validate PGN by loading and checking for any moves made
  const newGame = new Chess();
  const initialFen = newGame.fen(); // Save the initial position FEN
  newGame.loadPgn(pgnInput);

  // Check if loading the PGN modified the position, indicating successful load
  const pgnLoaded = newGame.fen() !== initialFen;


  if (!pgnLoaded) {
    alert('Invalid PGN format. Please check your PGN and try again.');
    return;
  }

  // Fetch user data from Firestore
  const docRef = doc(getFirestore(), 'users', user.uid);
  const docSnap = await getDoc(docRef);
  const data = docSnap.exists() ? docSnap.data() : {};
  const updatedFiles = { ...data.MoveTrainer || {} };
  let current = updatedFiles;

  // Use the selected folder path
  selectedItem.path.split('/').forEach(segment => {
    if (!current[segment]) current[segment] = {};
    current = current[segment];
  });
  if (!current.lines) current.lines = [];

  // Add the new PGN with the custom filename
  current.lines.push({ name: lineName, pgn: pgnInput });

  // Save to Firestore
  await setDoc(docRef, { MoveTrainer: updatedFiles }, { merge: true });
  alert('PGN saved successfully.');

  // Reset modal state
  setShowAddLineModal(false);
  setPgnInput('');
  setLineName(''); // Clear line name
  setNotifier((prevNotifier) => prevNotifier + 1);
};

const handleHint = () => {
  // Get the current correct move based on moveIndex
  const currentMove = correctMoves[moveIndex];
  if (!currentMove) return;

  // Define highlight colors
  const fromSquareHighlight = { backgroundColor: "rgba(255, 215, 0, 0.7)" };
  const toSquareHighlight = { backgroundColor: "rgba(255, 215, 0, 0.7)" };

  // Determine if this is the first hint for the current move index
  if (hintCount === 0) {
    // First hint: highlight only the "from" square
    setSquareStyles({
      ...squareStyling({ history: game.history({ verbose: true }) }),
      [currentMove.from]: fromSquareHighlight
    });
  } else {
    // Subsequent hints: highlight both "from" and "to" squares
    setSquareStyles({
      ...squareStyling({ history: game.history({ verbose: true }) }),
      [currentMove.from]: fromSquareHighlight,
      [currentMove.to]: toSquareHighlight
    });
  }

  // Increase the hint count for the current move
  setHintCount(hintCount + 1);
};

// Reset the hint count when the move index changes
useEffect(() => {
  setHintCount(0); // Reset hint count for each new move
}, [moveIndex]);

  const handleCurrentPathChange = (path) => {
    setCurrentDirectory(path);
  };

//  useEffect(() => {
//    if (user) {
//      fetchDirectories();
//    }
//  }, [user]);
//
//const fetchDirectories = async () => {
//  if (user) {
//    const docRef = doc(db, 'users', user.uid);
//    const docSnap = await getDoc(docRef);
//    if (docSnap.exists()) {
//      const data = docSnap.data();
//      // Ensure we are accessing the 'MoveTrainer' property, which contains the directory structure
//      setDirectories(data.MoveTrainer || {});
//    } else {
//      console.log('No such document!');
//    }
//  }
//};

const extractMovesAndNotesFromPgn = (pgn) => {
    const newGame = new Chess();

    // Step 1: Clean up the PGN input - Remove metadata, line breaks, and unnecessary whitespace
    let cleanedPgn = pgn
        .replace(/\[.*?\]/g, '')     // Remove all metadata in brackets []
        .replace(/\r?\n|\r/g, ' ')   // Replace all line breaks with spaces
        .replace(/\s+/g, ' ')         // Replace multiple spaces with a single space
        .trim();                      // Trim leading and trailing whitespace

    let initialNote = '';
    const initialNoteMatch = cleanedPgn.match(/^{([^}]*)}/);
    if (initialNoteMatch) {
        initialNote = initialNoteMatch[1].trim(); // Extract the note and trim any whitespace
        console.log("Initial note found:", initialNote);
        setInitialNote(initialNote); // Set initial note in state variable here
        // Remove the initial note from cleanedPgn for further processing
        cleanedPgn = cleanedPgn.replace(/^{[^}]*}\s*/, '');
    }

    // Step 2: Extract moves and notes without interpreting move numbers as moves
    const moveRegex = /(?:\d+\.+\s*)?([a-h1-8RNBQKx#+=O\-]+)\s*({[^}]*})?/g; // Updated to properly ignore move numbers
    let match;
    const movesWithNotes = [];

    while ((match = moveRegex.exec(cleanedPgn)) !== null) {
        const san = match[1]; // Extract the SAN (Standard Algebraic Notation) move
        const comment = match[2] ? match[2].replace(/[{}]/g, '').trim() : ''; // Extract the comment if present
        movesWithNotes.push({ san, note: comment });
    }


    // Step 3: Create the "cleaned PGN" consisting of just the moves with numbering added
    let numberedMoves = '';
    let moveNumber = 1;

    for (let i = 0; i < movesWithNotes.length; i++) {
        if (i % 2 === 0) {
            // Add move number before every White's move
            numberedMoves += `${moveNumber}. `;
            moveNumber++;
        }
        numberedMoves += `${movesWithNotes[i].san} `;
    }

    const finalPgn = `${numberedMoves.trim()}`;


    // Step 5: Load the cleaned PGN with headers into the chess instance
    const loadResult = newGame.loadPgn(finalPgn);

    // Step 6: Get the verbose history after loading to map moves to positions
    const history = newGame.history({ verbose: true });
    if (!history || history.length === 0) {
        console.error("History is null or empty after loading PGN.");
        return null; // Return early if there is no valid history
    }

    const moveObjects = history.map((move, index) => ({
        from: move.from,
        to: move.to,
        san: move.san,
        note: movesWithNotes[index]?.note || '', // Attach the parsed comment if it exists
    }));

    // Step 7: Chain nextMove to each move
    for (let i = 0; i < moveObjects.length - 1; i++) {
        moveObjects[i].nextMove = moveObjects[i + 1];
    }

    return moveObjects;
};


const handleFileSelect = (fileData, filePath) => {
    setIsFinished(false);
    setAttemptsTracker([]);
    setShouldInitialize(true);
    setRandomizedLines([]);

    if (fileData.isDirectory && fileData.lines) {
        setRandomizedLines(fileData.lines);
        setCurrentLineIndex(0);
        const firstLine = fileData.lines[0];
        initializeLine(firstLine, fileData.color);
    } else {
        initializeLine(fileData, fileData.color);
    }
};

const initializeLine = (fileData, folderColor) => {
    const fen = fileData.fen || DEFAULT_START_FEN;
    const isValidFen = fen.split(' ').length === 6;
    const newGame = new Chess(isValidFen ? fen : DEFAULT_START_FEN);
    setGame(newGame);
    const color = folderColor || 'white';
    setUserColor(color);

    if (fileData.pgn) {
        const pgnMoves = extractMovesAndNotesFromPgn(fileData.pgn);
        setCorrectMoves(pgnMoves);

        // Set notes separately based on extracted pgnMoves
        const extractedNotes = pgnMoves.map((move) => move.note || ''); // Extract notes from moves
        setNotes(extractedNotes);
    }

    setMoveIndex(0);
    setDisplayFen(newGame.fen());
    setSquareStyles({});
    handleModeChange('train');
    setSelectedMoveIndex(-1); // Reset the selectedMoveIndex to trigger note update
};

useEffect(() => {
    if (selectedMoveIndex >= -1 && notes[selectedMoveIndex]) {
        setCurrentNote(notes[selectedMoveIndex]);
        console.log(`Current Note Set to: "${notes[selectedMoveIndex]}" for move index: ${selectedMoveIndex}`);
    } else {
        setCurrentNote('');
        console.log('No note found for the current move index or index is invalid.');
    }
}, [selectedMoveIndex, notes]);


// Restart the current line (used in training mode)
const restartLine = () => {
    setMoveIndex(0);
    const newGame = new Chess(DEFAULT_START_FEN);
    setGame(newGame);
    setDisplayFen(DEFAULT_START_FEN);
    setIsFinished(false);
    setSquareStyles({});
    setMoves([]);
    setAttemptsTracker([]); // Reset the tracker
    setSelectedMoveIndex(-1);

    setShouldInitialize(true);  // Set flag to true
};

// Free move handling for add-line mode, with promotion handling
const handleAddLineMove = ({ sourceSquare, targetSquare }) => {
  const legalMoves = game.moves({ verbose: true });

  const isLegal = legalMoves.some(
    (move) => move.from === sourceSquare && move.to === targetSquare
  );

  if (isLegal) {
    const moveResult = game.move({ from: sourceSquare, to: targetSquare });
    if (moveResult) {
      const currentMoveIndex = game.history().length - 1;
//      console.log('Adding move:', moveResult.san, 'with note:', currentNote, 'at index:', currentMoveIndex);

      setMoves((prevMoves) => [...prevMoves, { san: moveResult.san, note: currentNote, moveIndex: currentMoveIndex }]); // Save move with note and index
//      setNotes((prevNotes) => [...prevNotes, { note: currentNote, moveIndex: currentMoveIndex - 1 }]); // Save the note with index
//      setCurrentNote(''); // Clear the note input for the next move
      setGame(game);
      setSelectedMoveIndex(currentMoveIndex);
      setDisplayFen(game.fen()); // Update displayFen with the new position
      setSquareStyles(squareStyling({ history: game.history({ verbose: true }) })); // Update square styles
    }
  } else {
//    console.log("Illegal move attempted");
  }
};

// Handle taking back the last move in add-line mode
const handleTakeBackMove = () => {

  if (moves.length === 0) {
//    console.log("No moves to take back");
    return; // No moves to take back
  }
  // Remove the last move from the moves array
  const updatedMoves = moves.slice(0, -1);
  setMoves(updatedMoves);  // Immediately set the updated moves in state

  // Reset the game to the initial position
  const newGame = new Chess(DEFAULT_START_FEN);

  // Replay all moves up to the last one in the updated moves array
  updatedMoves.forEach((move, index) => {
    const moveResult = newGame.move(move.san);
    console.log(`Replaying move ${index + 1}:`, move.san, "Result:", moveResult);
  });

  // Update the FEN display with the new game state after taking back
  const newFen = newGame.fen();

  // Set the updated game state and FEN display
  setGame(newGame);
  setDisplayFen(newFen); // Update FEN display
  setSquareStyles(squareStyling({ history: newGame.history({ verbose: true }) }));

  // Update the move index and selected move index to reflect the new length of moves
  setMoveIndex(updatedMoves.length);
  setSelectedMoveIndex(updatedMoves.length - 1);
};

const flashFeedback = (color, fromSquare, toSquare) => {
  const historicalStyles = squareStyling({ history: game.history({ verbose: true }) });
  const isPlayerTurn = (game.fen().split(' ')[1] === 'w' && userColor === 'white') ||
                       (game.fen().split(' ')[1] === 'b' && userColor === 'black');

  // Only flash green if it's the player's turn
  const highlightStyle =
    color === 'green' && !isPlayerTurn
      ? { backgroundColor: 'rgba(0, 255, 0, 0.6)' }
      : color === 'red'
      ? { backgroundColor: 'rgba(255, 0, 0, 0.6)' }
      : null;

  // Merge highlight with historical styles immediately if highlightStyle exists
  const newSquareStyles = highlightStyle
    ? {
        ...historicalStyles,
        [fromSquare]: highlightStyle,
        [toSquare]: highlightStyle,
      }
    : historicalStyles;

  // Set the square styles to include last move highlight immediately
  setSquareStyles(newSquareStyles);

  if (highlightStyle) {
    setTimeout(() => {
      // Remove flash but keep the last move highlight
      setSquareStyles(historicalStyles); // Retain only historical styles (last move highlight)
    }, 1000); // Flash for 1 second
  }
};


useEffect(() => {
  // When the selectedMoveIndex changes, update the current note
  if (selectedMoveIndex >= -1) {
    if (mode === 'addLine' || mode === 'edit') {
      // In add-line mode, 'notes' is an array of objects with 'moveIndex' and 'note'
      const noteObj = notes.find(note => note.moveIndex === selectedMoveIndex);

      if (noteObj) {
        setCurrentNote(noteObj.note);  // Set the note for the current move index
        console.log(`Add Line Mode: Setting current note to: "${noteObj.note}" for move index: ${selectedMoveIndex}`);
      } else {
        setCurrentNote('');  // Clear the note if no note exists for the current move index
        console.log('Add Line Mode: No note found for the current move index, clearing the current note.');
      }
    } else if (mode === 'train') {
      // In training mode, 'notes' is an array of strings where the index corresponds to the move
      if (notes.length > selectedMoveIndex) {
        const note = notes[selectedMoveIndex];

        if (note) {
          setCurrentNote(note);  // Set the note for the current move index
//          console.log(`Training Mode: Setting current note to: "${note}" for move index: ${selectedMoveIndex}`);
        } else {
          setCurrentNote('');  // Clear the note if no note exists
//          console.log('Training Mode: No note found for the current move index, clearing the current note.');
        }
      } else {
        setCurrentNote('');  // Clear the note if the index is out of bounds
//        console.log('Training Mode: Invalid move index, clearing the current note.');
      }
    } else {
      setCurrentNote('');  // Default to clearing the note for unexpected modes
      console.log('Unknown Mode: Clearing the current note.');
    }
  }
}, [selectedMoveIndex, notes, mode]);  // Dependencies: when selectedMoveIndex, notes, or mode change

// Handle saving the line in Add-Line Mode
const handleSaveLine = async (lineName, directory) => {
  if (!user) {
    alert('Please log in to save your lines.');
    return;
  }

  // Generate the PGN, inserting notes at the correct move index
  let pgnWithNotes = '';
  const history = game.history({ verbose: true });

  // Check if there is a note for the initial position (before the first move)
  const initialNoteObj = notes.find((n) => n.moveIndex === -1);
  if (initialNoteObj) {
    pgnWithNotes += `{${initialNoteObj.note}} `; // Add the initial note
  }

  // Iterate over the moves and add the notes associated with them
  history.forEach((move, index) => {
    const noteObj = notes.find((n) => n.moveIndex === index); // Find the note corresponding to this move
    const note = noteObj ? ` {${noteObj.note}}` : ''; // If a note exists, wrap it in curly braces
    pgnWithNotes += `${move.san}${note} `;
  });

  const pgn = pgnWithNotes.trim(); // Remove any trailing spaces

  if (pgn === "") {
    alert('No moves have been made. Please make moves before saving the line.');
    return;
  }

  const docRef = doc(db, 'users', user.uid);
  const docSnap = await getDoc(docRef);

  let data = {};
  if (docSnap.exists()) {
    data = docSnap.data();
  }

  // Update directories
  const updatedFiles = { ...data.MoveTrainer || {} };
  let current = updatedFiles;

  directory.split('/').forEach((segment) => {
    if (!current[segment]) {
      current[segment] = {};
    }
    current = current[segment];
  });

  if (!current.lines) {
    current.lines = [];
  }

  if (mode === 'edit' && selectedItem) {
    // Edit mode: Overwrite the existing line
    const lineIndex = current.lines.findIndex((line) => line.name === selectedItem.data.name);
    if (lineIndex !== -1) {
      current.lines[lineIndex] = { name: lineName || selectedItem.data.name, pgn };
    } else {
      alert('Error: Selected line not found for editing.');
      return;
    }
  } else {
    // Add mode: Add a new line
    current.lines.push({ name: lineName, pgn });
  }

  // Save to Firestore
  await setDoc(
    docRef,
    { MoveTrainer: updatedFiles },
    { merge: true }
  );

  setShowSaveModal(false);
  setIsSidebarCollapsed(false);
  setNotifier((prevNotifier) => prevNotifier + 1);

  alert(mode === 'edit' ? 'Line updated successfully.' : 'Line added successfully.');
};

const handleAddLine = async () => {
  if (selectedItem) {
    // Extract the first folder name from the path (e.g., "Caro Kann")
    const mainFolder = selectedItem.path.split('/')[0];

    try {
      // Retrieve the folder's color from Firebase
      const docRef = doc(getFirestore(), 'users', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        const folderData = userData.MoveTrainer?.[mainFolder];

        // If the folder has a color, use it. Otherwise, default to 'white'
        const folderColor = folderData?.color || 'white';

        // Set the userColor based on the folder's color
        setUserColor(folderColor);
      } else {
        setUserColor('white');
      }
    } catch (error) {
      console.error('Error fetching folder color from Firebase:', error);
      setUserColor('white');
    }
  } else {
    setUserColor('white');
  }
  setShowAddLineModal(true); // Show the modal to select add options
  handleModeChange('addLine');
  const currentGameMoves = game.history({ verbose: true });

  // Update the `moves` state with the current game history
  const allMoves = currentGameMoves.map(move => ({
    from: move.from,
    to: move.to,
    san: move.san,
    fen: game.fen(),  // Get the FEN after the move
  }));

  setMoves(allMoves);
};

const initializeTraining = () => {
    // Check if the user is playing black; if so, make the computer's first move
    if (userColor === 'black' && correctMoves.length > 0) {
        const firstMove = correctMoves[0];
        game.move({ from: firstMove.from, to: firstMove.to });
        setMoveIndex(1); // Move to user's turn
        setSelectedMoveIndex(game.history().length - 1);
        setDisplayFen(game.fen());
        flashFeedback('green', firstMove.from, firstMove.to); // Show visual feedback for computer's first move
    } else {
        setMoveIndex(0); // User starts if they are white
    }
};

useEffect(() => {
    if (shouldInitialize && game) {
        initializeTraining(); // Initialize the training after restart
        setShouldInitialize(false); // Reset the flag
    }
}, [shouldInitialize, game]);

const handleTrainingMove = ({ sourceSquare, targetSquare }) => {
    if (sourceSquare === targetSquare) return;

    const currentMove = correctMoves[moveIndex];
    const halfMoveIndex = Math.floor(moveIndex / 2); // Get half of moveIndex, rounded down

    if (currentMove && currentMove.from === sourceSquare && currentMove.to === targetSquare) {
        // Only add to attemptsTracker if it hasn't been attempted yet
        if (attemptsTracker[halfMoveIndex] === undefined) {
            setAttemptsTracker((prev) => [...prev, true]);
        }

        game.move({ from: sourceSquare, to: targetSquare });
        setMoveIndex(moveIndex + 1);
        setSelectedMoveIndex(game.history().length - 1);
        flashFeedback('green', sourceSquare, targetSquare);
        setDisplayFen(game.fen());

        // Automatically play opponent's move if available
        if (currentMove.nextMove) {
            setTimeout(() => {
                game.move({ from: currentMove.nextMove.from, to: currentMove.nextMove.to });
                setMoveIndex(moveIndex + 2);
                setSelectedMoveIndex(game.history().length - 1);
                flashFeedback('green', currentMove.nextMove.from, currentMove.nextMove.to);
                setDisplayFen(game.fen());
            }, 1000);
        }
    } else {
        // Record a false only on the first incorrect attempt
        if (attemptsTracker[halfMoveIndex] === undefined) {
            setAttemptsTracker((prev) => [...prev, false]);
        }
        flashFeedback('red', sourceSquare, targetSquare);
    }
};

useEffect(() => {
    // Check if training is finished
    if (correctMoves.length && moveIndex >= correctMoves.length) {
        setIsFinished(true);  // Mark as finished to trigger useEffect
    }
}, [moveIndex]);

// Calculate accuracy when isFinished is true and attemptsTracker is updated
useEffect(() => {
    if (isFinished) {
        const correctUserMovesCount = attemptsTracker.filter(Boolean).length;
        const totalUserMoves = attemptsTracker.length;  // Only first attempts are counted
        const accuracy = Math.round((correctUserMovesCount / totalUserMoves) * 100);

        setPercentageCorrect(accuracy);
    }
}, [isFinished, attemptsTracker]);

useEffect(() => {
    console.log('Current Note: ' + currentNote);
    console.log('Move Index: ' + moveIndex);
}, [currentNote]);

// Update the current note when selectedMoveIndex changes
useEffect(() => {
  if (selectedMoveIndex >= -1) {
    if (mode === 'addLine' || mode === 'edit') {
      const noteObj = notes.find(note => note.moveIndex === selectedMoveIndex);

      if (noteObj && currentNote !== noteObj.note) {
        // Only update if the note is different from the current one
        setCurrentNote(noteObj.note);
        console.log(`Add Line/Edit Mode: Setting current note to: "${noteObj.note}" for move index: ${selectedMoveIndex}`);
      } else if (!noteObj && currentNote !== '') {
        // If no note exists for the current move index, clear the current note
        setCurrentNote('');
        console.log('Add Line/Edit Mode: No note found for the current move index, clearing the current note.');
      }
    } else if (mode === 'train') {
      // Check if it's user's turn
      const currentTurn = (game.turn() === 'w') ? 'white' : 'black';
      const isUserTurn = (currentTurn === userColor);

      if (isUserTurn && notes.length > selectedMoveIndex && notes[selectedMoveIndex] !== currentNote) {
        const note = notes[selectedMoveIndex];

        if (note) {
          setCurrentNote(note);
//          console.log(`Training Mode: Setting current note to: "${note}" for move index: ${selectedMoveIndex}`);
        } else {
          setCurrentNote('');
//          console.log('Training Mode: No note found for the current move index, clearing the current note.');
        }
      }
    }
  }
}, [selectedMoveIndex, mode, notes, currentNote, userColor, game]);

//useEffect(() => {
//  if (mode === 'addLine' && notes.length > moveIndex + 1) {
//    setNotes((prevNotes) => prevNotes.slice(0, moveIndex + 1));
//    console.log(`Truncated notes to length ${moveIndex + 1} to match current move index.`);
//  }
//}, [moveIndex, mode]);

useEffect(() => {
  const previousMoveIndex = previousMoveIndexRef.current; // Get the previous move index
  if (previousMoveIndex >= -1 && currentNote !== '') {  // Only save if previous move index is valid
    setNotes((prevNotes) => {
      // Check if there's already a note for the previous move index
      const existingNoteIndex = prevNotes.findIndex(note => note.moveIndex === previousMoveIndex);

      // If there's an existing note and it's different, update it
      if (existingNoteIndex !== -1 && prevNotes[existingNoteIndex].note !== currentNote) {
        const updatedNotes = [...prevNotes];
        updatedNotes[existingNoteIndex].note = currentNote;
        console.log(`Updated note for move index ${previousMoveIndex}: ${currentNote}`);
        return updatedNotes;
      } else if (existingNoteIndex === -1) {
        // If there's no existing note, add a new one
        console.log(`Added new note for move index ${previousMoveIndex}: ${currentNote}`);
        return [...prevNotes, { moveIndex: previousMoveIndex, note: currentNote }];
      }
      return prevNotes; // Return the previous state if no changes
    });
  } else if (previousMoveIndex -1 && currentNote === '') {
    // Clear the note if the current note is empty
    setNotes((prevNotes) => {
      const newNotes = prevNotes.filter(note => note.moveIndex !== previousMoveIndex);
      if (newNotes.length !== prevNotes.length) {
        console.log(`Cleared note for move index ${previousMoveIndex}`);
      }
      return newNotes;
    });
  }

  // Update the previous move index ref
  previousMoveIndexRef.current = selectedMoveIndex;
}, [selectedMoveIndex, showSaveModal]);

const onSquareClick = (square) => {
  // Reset promotion state if it was open
  setShowSaveModal(false);
  setPromotionOpen(false);
  setPromotionDetails(null);

  const piece = game.get(square);
  const historicalStyles = squareStyling({ history: game.history({ verbose: true }) });

  // Case 1: Deselect the square if the same square is clicked again
  if (square === pieceSquare) {
    setPieceSquare("");
    setSquareStyles(historicalStyles);
    return;
  }

  // Case 2: Select a piece if it's the player's turn and the piece is the color of the current turn
  if (piece && piece.color === game.turn()) {
    const moves = game.moves({ square: square, verbose: true });
    const squaresToHighlight = moves.map(move => move.to);

    const darkSquare = 'var(--dark-square-color)';
    const lightSquare = 'var(--light-square-color)';

    const highlightStyles = squaresToHighlight.reduce((acc, curr) => {
      const squareColor = (curr.charCodeAt(0) - 'a'.charCodeAt(0) + parseInt(curr[1], 10)) % 2 === 0 ? lightSquare : darkSquare;
      const highlightColor = squareColor === lightSquare ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)';

      if (game.get(curr)) {
        return {
          ...acc,
          [curr]: {
            background: `
              linear-gradient(to bottom right, ${highlightColor} 30%, transparent 30%) 0 0,
              linear-gradient(to bottom left, ${highlightColor} 30%, transparent 30%) 100% 0,
              linear-gradient(to top left, ${highlightColor} 30%, transparent 30%) 100% 100%,
              linear-gradient(to top right, ${highlightColor} 30%, transparent 30%) 0 100%
            `,
            backgroundSize: "40% 40%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
          },
        };
      } else {
        return {
          ...acc,
          [curr]: {
            background: `radial-gradient(circle, ${highlightColor} 25%, transparent 27%)`,
            borderRadius: "50%",
          },
        };
      }
    }, {});

    // Update square styles with highlighted moves and the selected piece square
    setSquareStyles({
      ...historicalStyles,
      ...highlightStyles,
      [square]: { backgroundColor: "rgba(255, 255, 102, 0.6)" } // Light yellow background for the selected square
    });

    setPieceSquare(square);
  }
  // Case 3: Attempt to move the selected piece
  else if (pieceSquare) {
    const isLegalMove = game.moves({ verbose: true }).some(
      (move) => move.from === pieceSquare && move.to === square
    );

    if (isLegalMove) {
      if (mode === 'addLine' || mode === 'edit') {
        handleAddLineMove({ sourceSquare: pieceSquare, targetSquare: square });
      } else if (mode === 'train') {
        handleTrainingMove({ sourceSquare: pieceSquare, targetSquare: square });
      }
    } else {
      flashFeedback('red', pieceSquare, square); // Show flash for illegal moves
    }

    setPieceSquare("");
  } else {
    setSquareStyles(historicalStyles); // Reset styles for invalid selection
  }
};

  const squareStyling = ({ history }) => {
    const sourceSquare = history.length && history[history.length - 1].from;
    const targetSquare = history.length && history[history.length - 1].to;

    return {
      ...(history.length && {
        [sourceSquare]: {
          backgroundColor: "rgba(255, 255, 0, 0.3)"
        },
        [targetSquare]: {
          backgroundColor: "rgba(255, 255, 0, 0.5)"
        }
      })
    };
  };

const handlePromotion = (piece) => {
  // Check if there are promotion details available
  if (promotionDetails) {
    const { sourceSquare, targetSquare } = promotionDetails;

    // Execute the move with the selected promotion piece
    const moveResult = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: piece, // Pass the chosen piece (e.g., 'q' for queen, 'r' for rook)
    });

    // If the move is successful, update the game state and reset the promotion modal state
    if (moveResult) {
      setGame(game); // Update the game state
      setDisplayFen(game.fen()); // Update the display with the new position
      setSelectedMoveIndex(game.history().length - 1); // Update the move index
      setSquareStyles(squareStyling({ history: game.history({ verbose: true }) })); // Update square styles

      // Close the promotion modal and reset promotion details
      setPromotionOpen(false);
      setPromotionDetails(null);
      setPieceSquare(null);
    } else {
      console.error("Promotion move failed.");
    }
  }
};

const loadNextLine = () => {
    const nextIndex = currentLineIndex + 1;
    if (nextIndex < randomizedLines.length) {
        setCurrentLineIndex(nextIndex);
        setIsFinished(false);
        setAttemptsTracker([]);
        initializeLine(randomizedLines[nextIndex], userColor);
    }
};

  const handleCloseMessage = () => {
    setIsFinished(false); // Hide the message without any other action
    setIsSidebarCollapsed(false);
  };

  const handleManualEntry = (fromStart) => {
    setUseManualEntry(true);
    handleModeChange('addLine');
    setShowAddLineModal(false);
    setIsSidebarCollapsed(true);
    if (fromStart) {
        setMoves([]);
        setNotes([]);
        setGame(new Chess(DEFAULT_START_FEN));
        setDisplayFen(DEFAULT_START_FEN);
        setSquareStyles([]);
        setSelectedMoveIndex(-1);
      }
  };

const copyFenToClipboard = () => {
  const fen = game.fen();
  if (!navigator.clipboard) {
    console.error('Clipboard API not available.');
    return;
  }
  navigator.clipboard.writeText(fen).then(() => {
//    console.log('FEN copied to clipboard:', fen);
  }).catch(err => {
    console.error('Failed to copy FEN to clipboard:', err);
  });
};

const copyPgnToClipboard = () => {
  const pgn = game.pgn();
  if (!navigator.clipboard) {
    console.error('Clipboard API not available.');
    return;
  }
  navigator.clipboard.writeText(pgn).then(() => {
//    console.log('PGN copied to clipboard:', pgn);
  }).catch(err => {
    console.error('Failed to copy PGN to clipboard:', err);
  });
};

const openInLichess = () => {
  const fen = game.fen();
  const encodedPgn = encodeURIComponent(fen);
  const lichessUrl = `https://lichess.org/analysis/standard/${encodeURIComponent(fen)}?openBrowser=true`;
  window.open(lichessUrl, '_blank');
};

  useEffect(() => {
    const initializeUser = async () => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setHasSeenOpeningTrainer(data.hasSeenOpeningTrainer || false);
          if (!data.hasSeenOpeningTrainer) {
            setIsTourOpen(true);
            setDoingTours(true);
          }
        }
      }
    };

    initializeUser();
  }, [user]);

  useEffect(() => {
    const initializeUser = async () => {
      if (user && doingTours) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setHasSeenOpeningTrainer2(data.hasSeenOpeningTrainer2 || false);
          if (!data.hasSeenOpeningTrainer2 && selectedItem) {
            setIsTourOpen2(true);
          }
        }
      }
    };

    initializeUser();
  }, [user, selectedItem]);

const handleEditLine = async (linePath) => {
  console.log("handleEditLine triggered with linePath:", linePath);

  if (!linePath || !selectedItem || !selectedItem.data) {
    console.error("Invalid linePath or selected item. Cannot proceed with editing.");
    alert("Please select a valid line to edit.");
    return;
  }

  try {
    console.log("Fetching user data from Firestore...");
    // Fetch the user's data from Firestore
    const docRef = doc(getFirestore(), "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.warn("No user data found in Firestore.");
      alert("No data found for the user.");
      return;
    }

    console.log("User data fetched successfully.");
    const userData = docSnap.data();
    const selectedLine = selectedItem.data;

    console.log("Selected line data:", selectedLine);

    // Check if the selected item is valid and contains a PGN
    if (!selectedLine.pgn) {
      console.warn("The selected line does not contain a valid PGN.");
      alert("The selected line does not contain a valid PGN.");
      return;
    }

    console.log("Initializing Chess.js game instance...");
    // Parse the PGN and initialize the chess game
    const newGame = new Chess();
    const pgnMoves = extractMovesAndNotesFromPgn(selectedLine.pgn);

    if (!pgnMoves || pgnMoves.length === 0) {
      console.warn("No moves found in the PGN.");
      alert("No moves found in the PGN.");
      return;
    }

    console.log("PGN parsed successfully. Parsed moves:", pgnMoves);

    // Play through each move sequentially and apply to the Chess instance
    pgnMoves.forEach((move, index) => {
      const result = newGame.move({ from: move.from, to: move.to, promotion: move.promotion });
      if (!result) {
        console.warn(`Invalid move at index ${index}:`, move);
      }
    });

    console.log("All moves applied successfully.");

    // Set up the state with the line's data
    setGame(newGame);
    setDisplayFen(newGame.fen());
    setCorrectMoves(pgnMoves);

    console.log("Setting up notes from PGN moves...");
    // Extract notes from the parsed PGN moves
    const extractedNotes = pgnMoves.map((move, index) => ({
      moveIndex: index, // Set the index of the move
      note: move.note || "" // Assign the note, or an empty string if undefined
    }));

    setNotes(extractedNotes);

    console.log("Notes extracted and set:", extractedNotes);


    // Set initial mode, user color, and other properties
    handleModeChange("edit");
    setUserColor(selectedLine.color || "white");
    setMoveIndex(0);
    setSelectedMoveIndex(-1);
    setSquareStyles({});
    setIsFinished(false);

    console.log("Edit mode initialized successfully with the selected line.");
  } catch (error) {
    console.error("Error initializing edit mode:", error);
    alert("An error occurred while loading the line for editing. Please try again.");
  }
};

  return (
    <div>
      {showLoginMessage && (
        <div className="login-message">
          <button className="close-button" onClick={handleCloseLoginMessage}>×</button>
          <p><a href="/login">Log in</a> to save and train your openings.</p>
        </div>
      )}
      {isTourOpen && (
        <JoyrideWrapper
          steps={moveTrainerRide}
          run={isTourOpen}
          onFinish={async () => {
            try {
              // Close tour UI and set local state
              setIsTourOpen(false);
              setHasSeenOpeningTrainer(true);

              // Update Firestore to set hasSeenOpeningTrainer to true for the user
              if (user) {
                const userDocRef = doc(db, "users", user.uid);
                await setDoc(userDocRef, { hasSeenOpeningTrainer: true }, { merge: true });
              }
            } catch (error) {
              console.error("Error updating hasSeenOpeningTrainer in Firestore:", error.message);
            }
          }}
        />
      )}
      {isTourOpen2 && (
        <JoyrideWrapper
          steps={moveTrainerRide2}
          run={isTourOpen2}
          onFinish={async () => {
            try {
              // Close tour UI and set local state
              setIsTourOpen2(false);
              setHasSeenOpeningTrainer2(true);

              // Update Firestore to set hasSeenOpeningTrainer to true for the user
              if (user) {
                const userDocRef = doc(db, "users", user.uid);
                await setDoc(userDocRef, { hasSeenOpeningTrainer2: true }, { merge: true });
              }
            } catch (error) {
              console.error("Error updating hasSeenOpeningTrainer in Firestore:", error.message);
            }
          }}
        />
      )}
    <div className="move-trainer-wrapper">
    {promotionOpen && (
      <div className='promotion-modal-container'>
        <PromotionModal
          onPromote={handlePromotion}
          pieces={['q', 'r', 'n', 'b']}
          color={userColor === 'white' ? 'w' : 'b'}
        />
      </div>
    )}
      <div className={`file-structure-sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        <FileStructure
          onFileSelect={handleFileSelect}
          onAddLine={handleAddLine}
          handleEditLine={handleEditLine}
          onCurrentPathChange={handleCurrentPathChange}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          isSidebarCollapsed={isSidebarCollapsed}
          setIsSidebarCollapsed={setIsSidebarCollapsed}
          notifier={notifier}
        />
      </div>
      <div className="game-layout">
        <div className="chessboard-container">
            <canvas ref={canvasRef} style={{ zIndex: 100, position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}></canvas>

            <ChessboardCustom
              position={displayFen}
              onDrop={mode === 'addLine' || mode === 'edit' ? handleAddLineMove : handleTrainingMove}
              onSquareClick={onSquareClick} // Attach the click handler here
              orientation={userColor}
              squareStyles={squareStyles}
              updateChessboardSize={setChessboardSize}
              degreeOfBlindness='Normal'
            />
            {isFinished && (
              <div className="result-message">
                <h3>Training Complete!</h3>
                <p>
                  You got <strong>{percentageCorrect}%</strong> of the moves correct.
                </p>
                <button onClick={handleCloseMessage}>Close</button> {/* New close button */}
                <button onClick={restartLine}>Retry</button>
                <div className="line-info-container">
                  {randomizedLines.length > 0 && (
                    <p>Line {currentLineIndex + 1} of {randomizedLines.length}</p>
                  )}
                  {randomizedLines.length > currentLineIndex + 1 && (
                    <button onClick={loadNextLine}>Next Line</button>
                  )}
                </div>
              </div>
            )}
        </div>
          {/* Conditionally render note-container inside move-history-container based on screen width */}
      {isWideScreen && (
        <div className={(mode === 'addLine' || mode === 'edit') ? 'note-container2' : 'note-container'}>
          {/* Header bar above the note box */}
          <div className="note-header desktop-only">
            <h4>Move Notes</h4>
          </div>

          {(mode === 'addLine' || mode === 'edit') ? (
            <div>
              <textarea
                id="noteInput"
                className="note-input"
                value={selectedMoveIndex === -1 ? initialNote : currentNote}
                onChange={(e) => {
                  if (selectedMoveIndex === -1) {
                    setInitialNote(e.target.value);
                  } else {
                    setCurrentNote(e.target.value);
                  }
                }}
                placeholder="Enter note for this move..."
              />
            </div>
          ) : (
            (() => {
              const verboseHistory = game.history({ verbose: true });

              const prevIndex = selectedMoveIndex > 0 ? selectedMoveIndex - 1 : -1;
              const previousNoteContent = prevIndex === -1 ? initialNote : notes[prevIndex];
              const currentNoteContent = selectedMoveIndex === -1 ? initialNote : currentNote;

              const getMoveInfo = (index) => {
                if (index === -1 || !verboseHistory[index]) return null;
                const move = verboseHistory[index];
                const moveNumber = Math.floor(index / 2) + 1;
                const isWhiteMove = index % 2 === 0;
                return {
                  moveNumber,
                  san: move.san,
                  prefix: isWhiteMove ? `${moveNumber}.` : `${moveNumber}...`
                };
              };

              const prevMoveInfo = getMoveInfo(prevIndex);
              const currentMoveInfo = getMoveInfo(selectedMoveIndex);

              // Create a unique key so the component remounts when notes or index change
              const fadeKey = `${selectedMoveIndex}-${previousNoteContent || ''}-${currentNoteContent || ''}`;

              return (
                <div className="note-display fade-in" key={fadeKey}>
                  {previousNoteContent && prevMoveInfo && (
                    <p className="note-item">
                      <span className="note-move-info">{prevMoveInfo.prefix} {prevMoveInfo.san}</span><br />
                      {previousNoteContent}
                    </p>
                  )}
                  {currentNoteContent && currentMoveInfo && (
                    <p className="note-item">
                      <span className="note-move-info">{currentMoveInfo.prefix} {currentMoveInfo.san}</span><br />
                      {currentNoteContent}
                    </p>
                  )}
                </div>
              );
            })()
          )}
        </div>
      )}

        <div className="move-history-container">
          {(mode === 'edit' || mode === 'addLine') && (
            <EngineMoves fen={displayFen} onMoveData={handleMoveData} game={game} />
          )}


          {/* Always render move history inside move-history-container */}
          {renderMoveHistory(
            {
              state: {
                history: game.history({ verbose: true }),
                initialFen: DEFAULT_START_FEN,
                fen: game.fen(),
                selectedMoveIndex: selectedMoveIndex,
                mode: mode,
                isMoveTrainer: true
              },
              setDisplayFen,
              setSquareStyles,
              setSelectedMoveIndex,
              setShowSaveModal,
              moveHistoryRef,
              openInLichess,
              handleHint,
              handleTakeBackMove
            },
            game.history({ verbose: true }),
            (data) => data
          )}
        </div>

        {/* Render note-container separately if screen width is less than or equal to 768px */}
        {!isWideScreen && (
          <div className="note-container">
            {mode === 'addLine' ? (
              <div>
                <textarea
                  id="noteInput"
                  className="note-input"
                  value={selectedMoveIndex === -1 ? initialNote : currentNote}  // Display initialNote if selectedMoveIndex is -1
                  onChange={(e) => {
                    if (selectedMoveIndex === -1) {
                      setInitialNote(e.target.value);  // Update the initial note if it's the initial position
                    } else {
                      setCurrentNote(e.target.value);  // Update current note for other moves
                    }
                  }}
                  placeholder="Enter note for this move..."
                />
              </div>
            ) : (
              selectedMoveIndex === -1 && initialNote ? (
                <p><strong>Initial Note:</strong> {initialNote}</p>  // Display initial note if selectedMoveIndex is -1
              ) : currentNote ? (
                <p><strong>Note:</strong> {currentNote}</p>  // Otherwise, display the current note
              ) : (
                <p><strong>Note:</strong></p>  // Default text if no note exists
              )
            )}
          </div>
        )}
        {showSaveModal && (
          <div className="modal-overlay">
            <div className="save-line-modal">
              <SaveLineModal
                moves={moves}
                onSave={handleSaveLine}
                onClose={() => setShowSaveModal(false)}
                directories={directories}
                currentDirectory={currentDirectory} // Pass currentDirectory to SaveLineModal
              />
            </div>
          </div>
        )}

      </div>
      {showAddLineModal && (
        <div className="modal-overlay">
          <div className="save-line-modal">
            <h2>Add Line Via:</h2>
            <div className="entry-methods">
              <button onClick={() => handleManualEntry(true)}>Start Position</button>
              <button onClick={() => handleManualEntry(false)}>Current Position</button>
              <button onClick={() => setUseManualEntry(false)}>PGN</button>
            </div>
            {!useManualEntry && (
              <div className="pgn-input">
                <input
                  type="text"
                  placeholder="Enter file name"
                  value={lineName}
                  onChange={(e) => setLineName(e.target.value)}
                />
                <textarea
                  placeholder="Enter PGN"
                  value={pgnInput}
                  onChange={(e) => setPgnInput(e.target.value)}
                />
                <button onClick={handleSavePgn}>Save PGN</button>
              </div>
            )}
            <button onClick={() => setShowAddLineModal(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default MoveTrainer;
