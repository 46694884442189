//import React, { createContext, useState, useContext, useEffect } from 'react';
//import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, updateProfile } from "firebase/auth";
//import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
//import '../firebase-config';
//import { db } from '../firebase-config';
//
//const auth = getAuth();
//const UserContext = createContext();
//
//export const UserProvider = ({ children }) => {
//    const [user, setUser] = useState(null);
//    const [username, setUsername] = useState('');
//
//    useEffect(() => {
//        setPersistence(auth, browserLocalPersistence)
//            .then(() => {
//                const unsubscribe = onAuthStateChanged(auth, async (user) => {
//                    if (user) {
//                        const userRef = doc(db, "users", user.uid);
//                        const userSnap = await getDoc(userRef);
//
//                        // Retrieve or create user data in Firestore
//                        if (userSnap.exists()) {
//                            const data = userSnap.data();
//                            setUsername(data.username || "Anonymous");
//
//                            // Check if the displayName is already set on Firebase Authentication user profile
//                            if (!user.displayName && data.username) {
//                                // Update the Firebase Auth profile to include displayName
//                                await updateProfile(user, {
//                                    displayName: data.username,
//                                });
//                                console.log("Display name set on Firebase Auth profile.");
//                            }
//
//                        } else {
//                            // If user document doesn't exist in Firestore, create it
//                            await setDoc(userRef, {
//                                username: "Anonymous",
//                            });
//                            setUsername("Anonymous");
//
//                            // Set the displayName in Firebase Authentication profile
//                            await updateProfile(user, {
//                                displayName: "Anonymous",
//                            });
//                            console.log('Default display name set on Firebase Auth profile.');
//                        }
//
//                        setUser(user);
//                    } else {
//                        setUser(null);
//                        setUsername('Guest');
//                    }
//                });
//                return () => unsubscribe(); // Cleanup subscription on unmount
//            })
//            .catch((error) => {
//                console.error("Firebase auth persistence error:", error);
//            });
//    }, []);
//
//    return (
//        <UserContext.Provider value={{ user, username }}>
//            {children}
//        </UserContext.Provider>
//    );
//};
//
//export const useUser = () => useContext(UserContext);

import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, updateProfile } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import '../firebase-config';
import { db } from '../firebase-config';
import { getPremiumStatus } from './GetCheckoutUrl'; // Import getPremiumStatus
import { useMediaQuery } from 'react-responsive';

const auth = getAuth();
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState('');
    const [isPremium, setIsPremium] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                const unsubscribe = onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        const userRef = doc(db, "users", user.uid);
                        const userSnap = await getDoc(userRef);

                        // Retrieve or create user data in Firestore
                        if (userSnap.exists()) {
                            const data = userSnap.data();
                            setUsername(data.username || "Anonymous");

                            // Check if the displayName is already set on Firebase Authentication user profile
                            if (!user.displayName && data.username) {
                                await updateProfile(user, {
                                    displayName: data.username,
                                });
                                console.log("Display name set on Firebase Auth profile.");
                            }

                        } else {
                            await setDoc(userRef, {
                                username: "Anonymous",
                            });
                            setUsername("Anonymous");

                            await updateProfile(user, {
                                displayName: "Anonymous",
                            });
                            console.log('Default display name set on Firebase Auth profile.');
                        }

                        // Check for premium status using the imported getPremiumStatus function
                        try {
                            const premiumStatus = await getPremiumStatus(user);
                            setIsPremium(premiumStatus);
                        } catch (error) {
                            console.error("Error checking premium status:", error);
                        }

                        setUser(user);
                    } else {
                        setUser(null);
                        setUsername('Guest');
                        setIsPremium(false);
                    }
                });
                return () => unsubscribe();
            })
            .catch((error) => {
                console.error("Firebase auth persistence error:", error);
            });
    }, []);

    return (
        <UserContext.Provider value={{ user, username, isPremium, isMobile }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
