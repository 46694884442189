import React from "react";
import "../styles/linkTree.css"; // Add your styles here or use inline styles
import EnableScrollOnPage from "../Integrations/EnableScroll";

const LinkTree = () => {
  return (
    <div className="linktree-container">
      <EnableScrollOnPage />
      <div className="linktree-logo">
        <img src="/logo.png" alt="TrainChess Logo" />
      </div>
      <h1 className="linktree-title">Train Chess</h1>
      <p className="linktree-bio">
        Welcome to Train Chess. Let's get better.
      </p>

      <div className="linktree-links">
        <a href="https://trainchess.net" target="_blank" rel="noopener noreferrer">
          Visit TrainChess.net
        </a>
        <a href="https://apps.apple.com/us/app/theexchange/id6473348782" target="_blank" rel="noopener noreferrer">
          The Exchange on App Store
        </a>
        <a href="https://www.chess.com/club/the-commoners-guild" target="_blank" rel="noopener noreferrer">
          Join Our Chess.com Club
        </a>
        <a href="https://www.instagram.com/train.chess?igsh=MjNsanZ6bDM4NG91&utm_source=qr" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>
        <a href="https://www.youtube.com/channel/UCO7Guyli8rWF3CqVOIM1nZA" target="_blank" rel="noopener noreferrer">
          YouTube Channel
        </a>
      </div>
    </div>
  );
};

export default LinkTree;