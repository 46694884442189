import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { UserProvider } from './Integrations/UserContext'; // Import the UserProvider
import { TrainingProvider } from "./Integrations/TrainingContext";
import TrainingOverlay from "./Integrations/TrainingOverlay";
import Header from './Integrations/Headers/Header';
import HomePage from './pages/HomePage';
import Play from './pages/Play';
import NotFoundPage from './pages/NotFoundPage';
import Login from './pages/Login';
import EndgamePositions from './pages/EndgamePositions';
import Puzzles from './pages/Puzzles';
import SignUp from './pages/SignUp';
import './firebase-config'; // Ensure Firebase is initialized here if not already done elsewhere
import LoadGame from './pages/LoadGame';
import Settings from './pages/Settings'; // Adjust the import path as needed
import { ThemeProvider } from './Integrations/ThemesContext'; // Import ThemeProvider
import { useNavigate } from 'react-router-dom';
import ThemeApplier from './Integrations/ThemeApplier';
import About from './pages/About';
import BlindfoldPuzzles from './pages/BlindfoldPuzzles';
import CalculationPuzzles from './pages/CalculationPuzzles';
import MoveTrainer from './pages/MoveTrainer';
import FindFork from './pages/FindFork';
import CoordinateTrainer from './pages/CoordinateTrainer';
import TimedPuzzles from './pages/TimedPuzzles';
import ClubPage from './pages/ClubPage';
import VoteChess from './pages/VoteChess';
import PrivacyPolicy from './pages/Help/PrivacyPolicy';
import TrainChessOpenings from './pages/TrainChessOpenings';
import ManageSubscription from './pages/ManageSubscription';
import GameAnalysis from './pages/GameAnalysis';
import Analyze from './pages/Analyze';
import { GameStateProvider } from "./Integrations/GameStateContext";
import Test from './Integrations/Test';
import Train from './pages/Train';
import PlayGamesOptions from './pages/PlayGamesOptions'; 
import TheExchangeLandingPage from './pages/TheExchange/linkpage';
import LinkTree from './pages/LinkTree';
// Import the new help components
import Help from './pages/Help/Help'; // Main Help Page
import CalculationPuzzlesHelp from './pages/Help/calculationpuzzles'; // Specific Help Page for Calculation Puzzles
import FindTheForkHelp from './pages/Help/findthefork'; // Specific Help Page for Calculation Puzzles
import CoordinateTrainerHelp from './pages/Help/coordinatetrainer'; // Specific Help Page for Calculation Puzzles
import PlayBlindfoldHelp from './pages/Help/playblindfold'; // Specific Help Page for Calculation Puzzles
import PuzzlesHelp from './pages/Help/puzzles'; // Specific Help Page for Calculation Puzzles
import BlindfoldPuzzlesHelp from './pages/Help/blindfoldpuzzles'; // Specific Help Page for Calculation Puzzles
import ContactPage from './pages/Help/contact'; // Specific Help Page for Calculation Puzzles
import AlgebraicNotation from './pages/Help/algebraicnotation'; // Specific Help Page for Calculation Puzzles
import RatingMetrics from './pages/Help/ratingmetrics'; // Specific Help Page for Calculation Puzzles
import Welcome from './pages/Help/Welcome'; // Specific Help Page for Calculation Puzzles
import GameAnalysisHelp from './pages/Help/gameanalysis'; // Specific Help Page for Calculation Puzzles
import EndgamesHelp from './pages/Help/endgames'; // Specific Help Page for Calculation Puzzles
import CoordinateTrainerType from './pages/CoordinateTrainerType';
import SupportPage from './pages/TheExchange/support';
import PrivacyPolicyPage from './pages/TheExchange/privacypolicy';
import TerminateAccount from './pages/TheExchange/terminateaccount';
import ChildSafety from './pages/TheExchange/childsafety';
import Minigames from './pages/Minigames';

function App() {
  return (
    <Router>
      <UserProvider> {/* Wrap the routes with UserProvider */}
        <ThemeProvider> {/* Additionally, wrap the routes with ThemeProvider */}
          <GameStateProvider>
            <TrainingProvider>
              <ThemeApplier />
              <RoutesWithHeader />
              <TrainingOverlay />
            </TrainingProvider>
          </GameStateProvider>
        </ThemeProvider>
      </UserProvider>
    </Router>
  );
}

const RoutesWithHeader = () => {
  let location = useLocation();

  return (
    <>
      {location.pathname !== '/' && <Header />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/train" element={<Train />} />
        <Route path="/playgames" element={<PlayGamesOptions />} />
        <Route path="/play" element={<PlayWrapper />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/loadgame" element={<LoadGame />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/endgames" element={<EndgamePositions />} />
        <Route path="/oldpuzzles" element={<Puzzles />} />
        <Route path="/blindfoldpuzzles" element={<BlindfoldPuzzles />} />
        <Route path="/calculationpuzzles" element={<CalculationPuzzles />} />
        <Route path="/puzzles" element={<TimedPuzzles />} />
        <Route path="/findthefork" element={<FindFork />} />
        <Route path="/coordinatetrainer" element={<CoordinateTrainer />} />
        <Route path="/coordinatetrainer2" element={<CoordinateTrainerType />} />
        <Route path="/openingtrainer" element={<MoveTrainer />} />
        <Route path="/openingtrainer/browse" element={<TrainChessOpenings />} />
        <Route path="/teamchess" element={<VoteChess />} />
        <Route path="/gameanalysis" element={<GameAnalysis />} />
        <Route path="/analyze" element={<Analyze />} />
        <Route path="/managesubscription" element={<ManageSubscription />} />
        <Route path="/club" element={<ClubPage />} />
        <Route path="/minigames" element={<Minigames />} />
        <Route path="/about" element={<About />} />
        <Route path="/linktree" element={<LinkTree />} />
        <Route path="/help" element={<Help />} /> {/* Main Help Page */}
        <Route path="/privacypolicy" element={<PrivacyPolicy />} /> {/* Calculation Puzzles Help */}
        <Route path="/calculationpuzzles/help" element={<CalculationPuzzlesHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/findthefork/help" element={<FindTheForkHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/coordinatetrainer/help" element={<CoordinateTrainerHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/playblindfold/help" element={<PlayBlindfoldHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/puzzles/help" element={<PuzzlesHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/blindfoldpuzzles/help" element={<BlindfoldPuzzlesHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/algebraicnotation" element={<AlgebraicNotation />} /> {/* Calculation Puzzles Help */}
        <Route path="/ratingmetrics" element={<RatingMetrics />} /> {/* Calculation Puzzles Help */}
        <Route path="/gameanalysis/help" element={<GameAnalysisHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/endgames/help" element={<EndgamesHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/contact" element={<ContactPage />} /> {/* Calculation Puzzles Help */}
        <Route path="/welcome" element={<Welcome />} /> {/* Calculation Puzzles Help */}
        <Route path="/test" element={<Test />} /> {/* Calculation Puzzles Help */}
        <Route path="/theexchange/support" element={<SupportPage />} /> {/* Calculation Puzzles Help */}
        <Route path="/theexchange/privacypolicy" element={<PrivacyPolicyPage />} /> {/* Calculation Puzzles Help */}
        <Route path="/theexchange" element={<TheExchangeLandingPage />} /> {/* Calculation Puzzles Help */}
        <Route path="/theexchange/terminate" element={<TerminateAccount />} /> {/* Calculation Puzzles Help */}
        <Route path="/theexchange/childsafety" element={<ChildSafety />} /> {/* Calculation Puzzles Help */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

// Create a wrapper component for Play to inject the navigate function
const PlayWrapper = () => {
  let navigate = useNavigate();
  return <Play navigate={navigate} />;
}

export default App;
