import React, { useRef, useState, useEffect } from "react";
import GameRetriever from "../Integrations/GameRetriever";
import "../styles/GameAnalysis.css";
import { useUser } from "../Integrations/UserContext";
import { analyzeGame } from "../Integrations/helperFunctions";
import ChessboardCustom from "../components/ChessboardCustom";
import { renderMoveHistory } from "../Integrations/MoveHistoryTable";
import { Chess } from 'chess.js';
import PromptBox from "../Integrations/PromptBox";
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler
import { useGameState } from "../Integrations/GameStateContext";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import LoadGameSidebar from "../Integrations/LoadGameSidebar";
import JoyrideWrapper from '../Integrations/JoyrideWrapper';
import { gameAnalysisRide, gameAnalysisRide2 } from '../Integrations/Joyrides';
import EngineMoves from "../Integrations/EngineMoves";
import { drawEngineArrow, highlightLastMove, handleSidelineMove, handleSidelineSquareClick, onDropWithSidelines } from "../Integrations/helperFunctions";
import PromotionModal from '../Integrations/PromotionModal';

const GameAnalysis = ({ uid }) => {
  const { user, isMobile } = useUser();
  const [selectedGame, setSelectedGame] = useState(null);
  const [customPGN, setCustomPGN] = useState("");
  const [customPlayerColor, setCustomPlayerColor] = useState("White");
  const [playerColor, setPlayerColor] = useState("White");
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const [reviewingGame, setReviewingGame] = useState(new Chess()); // Chess instance
  const [isReviewing, setIsReviewing] = useState(false); // Boolean for UI state
  const [analysisComplete, setAnalysisComplete] = useState(false); // New state for completion status
  const [squareStyles, setSquareStyles] = useState({}); // Store styles for flashing feedback
  const [selectedMoveIndex, setSelectedMoveIndex] = useState(-1);
  const [displayFen, setDisplayFen] = useState('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
  const [ratings, setRatings] = useState({});
  const { gameState, updateGameState } = useGameState();
  const [checkingWithAi, setCheckingWithAi] = useState(false);
  const chessboardRef = useRef();
  const [gameName, setGameName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState("GameRetriever");
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isTour2Open, setIsTour2Open] = useState(false);
  const [orientation, setOrientation] = useState("White");
  const [moveData, setMoveData] = useState([]);
  const canvasRef = useRef(null);
  const [chessboardSize, setChessboardSize] = useState(null);
  const moveHistoryRef = useRef(null);
  const sidelineRef = useRef(null);
  const [sidelines, setSidelines] = useState([]);
  const sidelinesAllowed = true;
  const [selectedSidelineIndex, setSelectedSidelineIndex] = useState(null);
  const [selectedSidelineMoveIndex, setSelectedSidelineMoveIndex] = useState(0);
  const [promotionDetails, setPromotionDetails] = useState(null);
  const [pieceSquare, setPieceSquare] = useState(null);
  const restoreSquareStyles = useRef({});
  const [promotionOpen, setPromotionOpen] = useState(null);
  const [onlyUserMoves, setOnlyUserMoves] = useState(true);
  const [flagsForReview, setFlagsForReview] = useState({}); // Separate state for flags

    useEffect(() => {
        console.log('Sidelines analysis game: ' + JSON.stringify(sidelines));
    }, [sidelines]);

    useEffect(() => {
        setOrientation(playerColor);
          updateGameState({
            playerColor,
          });

    }, [playerColor]);

    useEffect(() => {
        const checkIfFirstVisit = async () => {
            const userRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const data = userDoc.data();
                if (!data.hasSeenGameAnalysis) {
                    setIsTourOpen(true); // Start tour if it’s the user’s first visit
                    await setDoc(userRef, { hasSeenGameAnalysis: true }, { merge: true });
                }
                if (!data.hasSeenGameAnalysis2 && isReviewing) {
                    setIsTour2Open(true); // Start tour if it’s the user’s first visit
                    await setDoc(userRef, { hasSeenGameAnalysis2: true }, { merge: true });
                }
            }
        };

        if (user) {
            checkIfFirstVisit();
        }
    }, [user, isReviewing]);

const encodeSidelines = (sidelines) => {
  // Encode the entire sidelines structure, including nulls
  try {
    return JSON.stringify(sidelines);
  } catch (error) {
    console.error("Failed to encode sidelines:", error);
    return "[]"; // Return an empty array as fallback
  }
};

const decodeSidelines = (encodedSidelines) => {
  try {
    const decoded = JSON.parse(encodedSidelines);
    if (Array.isArray(decoded)) {
      return decoded;
    } else {
      console.warn("Decoded sidelines is not an array. Returning empty array.");
      return [];
    }
  } catch (error) {
    console.error("Failed to decode sidelines:", error);
    return [];
  }
};

const handleLoadGame = async (game) => {
  if (!game || !game.analysis) {
    console.error("Invalid game data or missing analysis.");
    alert("Game data is invalid or missing analysis.");
    return;
  }

  try {
    const newReviewingGame = new Chess();

    game.analysis.forEach((moveData, index) => {
      if (!moveData.move) {
        throw new Error(`Invalid move at index ${index}: ${JSON.stringify(moveData)}`);
      }
      const result = newReviewingGame.move(moveData.move);
      if (!result) {
        throw new Error(`Failed to apply move at index ${index}: ${moveData.move}`);
      }
    });

    const decodedSidelines = decodeSidelines(game.sidelines || "[]");

    updateGameState({ ...game, sidelines: decodedSidelines });
    setPlayerColor(game.playerColor);
    setReviewingGame(newReviewingGame);
    setIsReviewing(true);
    setSidelines(decodedSidelines);

    alert(`Game "${game.id}" loaded successfully.`);
  } catch (error) {
    console.error("Error loading game:", error);
    alert("Failed to load the game. Please check the analysis or try again.");
  }
};

  const flipBoard = () => {
    setOrientation((prevOrientation) => (prevOrientation === "White" ? "Black" : "White"));
  };

  const onClose = () => {
    setShowSaveModal(false);
  };

const cleanData = (data) => {
  if (Array.isArray(data)) {
    return data
      .map((item) => {
        if (Array.isArray(item)) {
          return item.map((subItem) => (typeof subItem === "object" ? cleanData(subItem) : subItem)); // Clean nested arrays
        }
        return typeof item === "object" && item !== null ? cleanData(item) : item;
      })
      .filter((item) => item !== undefined); // Remove undefined values but keep null
  } else if (typeof data === "object" && data !== null) {
    return Object.fromEntries(
      Object.entries(data)
        .filter(([_, value]) => value !== undefined) // Remove undefined but keep null
        .map(([key, value]) => [key, typeof value === "object" ? cleanData(value) : value])
    );
  }
  return data; // Return other values as-is
};

const handleSave = async () => {
  if (!gameName.trim()) {
    alert("Please provide a name for the game.");
    return;
  }

  setIsSaving(true);
  try {
    const userUid = user?.uid;
    if (!userUid) {
      throw new Error("User UID not found.");
    }

    const encodedSidelines = encodeSidelines(sidelines);

    const gameDoc = doc(db, "users", userUid, "reviewedGames", gameName);
    await setDoc(gameDoc, {
      ...gameState,
      sidelines: encodedSidelines, // Save encoded sidelines
      timestamp: new Date(),
    });

    alert("Game saved successfully!");
    onClose();
    setIsReviewing(false);
  } catch (error) {
    console.error("Error saving game:", error);
    alert("Failed to save the game.");
  } finally {
    setIsSaving(false);
  }
};

const handleAiCheckChange = (isChecking) => {
  setCheckingWithAi(isChecking); // Update the state in GameAnalysis
};

const flipSquare = (square) => {
  const file = square[0];
  const rank = square[1];

  // Map the file (a-h) to its flipped counterpart
  const flippedFile = String.fromCharCode('h'.charCodeAt(0) - (file.charCodeAt(0) - 'a'.charCodeAt(0)));

  // Flip the rank (1-8)
  const flippedRank = (9 - parseInt(rank, 10)).toString();

  return `${flippedFile}${flippedRank}`;
};

const handleHint = () => {
  if (!gameState.analysis || selectedMoveIndex === null || selectedMoveIndex <= 0) {
    console.error("No hint available.");
    alert("No threats available or invalid move index.");
    return;
  }

  try {
    const tempGame = new Chess(); // Start from the initial FEN
    console.log("Starting from an empty chess game.");

    // Replay moves from the start up to the selected move index
    for (let i = 0; i < selectedMoveIndex; i++) {
      const move = gameState.analysis[i]?.move; // Get the move in SAN format
      if (!move || typeof move !== "string") {
        console.error(`Move at index ${i} is invalid or not a string:`, move);
        throw new Error(`Invalid move in analysis at index ${i}.`);
      }
      console.log(`Playing move ${i + 1}: ${move}`);
      const result = tempGame.move(move); // Play the move on the temporary game
      if (!result) {
        console.error(`Failed to play move: ${move} at index ${i}.`);
        throw new Error("Invalid move detected during replay.");
      }
    }

    const previousMoveAnalysis = gameState.analysis[selectedMoveIndex - 1];
    console.log("Previous move analysis:", previousMoveAnalysis);

    if (previousMoveAnalysis?.recommendedMove) {
      const move = previousMoveAnalysis.recommendedMove; // Example: "e2e4"
      console.log("Recommended move for threat:", move);

      // Convert the move to "from" and "to" squares
      const fromSquare = move.standard.slice(0, 2);
      const toSquare = move.standard.slice(2, 4);

      // Flip the coordinates if the player color is Black
      const flippedFromSquare = gameState.playerColor === "Black" ? flipSquare(fromSquare) : fromSquare;
      const flippedToSquare = gameState.playerColor === "Black" ? flipSquare(toSquare) : toSquare;

      console.log(`Drawing threat arrow from ${flippedFromSquare} to ${flippedToSquare}`);
      chessboardRef.current.drawArrow(flippedFromSquare, flippedToSquare, 'rgba(255, 0, 0, 0.8)'); // Draw the arrow in red
    } else {
      console.warn("No recommended move found for the previous position.");
      alert("No threats available for the previous position.");
    }
  } catch (error) {
    console.error("Failed to process threat:", error);
    alert("Failed to generate threat. The move might be invalid.");
  }
};

////////////////////////////////////// Highlight last move

  useEffect(() => {
      highlightLastMove(selectedMoveIndex, reviewingGame, setSquareStyles);
  }, [selectedMoveIndex, reviewingGame]);

useEffect(() => {
  console.log("Running sideline highlight effect...");
  console.log("Selected Sideline Index:", selectedSidelineIndex);
  console.log("Selected Sideline Move Index:", selectedSidelineMoveIndex);

  if (
    selectedSidelineIndex !== null &&
    selectedSidelineMoveIndex !== null &&
    sidelines[selectedMoveIndex]?.[selectedSidelineIndex]
  ) {
    const sideline = sidelines[selectedMoveIndex][selectedSidelineIndex];
    const tempGame = new Chess(sideline.initialFen); // Start from the initial FEN of the sideline

    sideline.moves.slice(0, selectedSidelineMoveIndex + 1).forEach((move, idx) => {
      tempGame.move(move);
      console.log(`Sideline move ${idx + 1}: ${move}`);
    });

    // Call the existing highlightLastMove function
    highlightLastMove(selectedSidelineMoveIndex, tempGame, setSquareStyles);
  }
}, [selectedSidelineIndex, selectedSidelineMoveIndex, reviewingGame, sidelines]);

////////////////////////////////////// Highlight last move


const handleGameSelection = async (gameWrapper) => {
  setCheckingWithAi(false);
  setFlagsForReview({});

  const game = gameWrapper.game || gameWrapper;
  if (!game || !game.pgn) {
    console.error("Invalid game data");
    return;
  }
  setPlayerColor(game.playerColor);
  const newReviewingGame = new Chess();
  let moves;
  try {
    moves = parsePGNMoves(game.pgn);
//    console.log("Parsed moves:", moves);
  } catch (parseError) {
    console.error("Error parsing PGN:", parseError);
    return;
  }

  try {
    moves.forEach((move, index) => {
//      console.log(`Attempting to play move ${index + 1}: ${move}`);
      if (!newReviewingGame.move(move)) {
        throw new Error(`Invalid move at index ${index}: ${move}`);
      }
    });

    setReviewingGame(newReviewingGame); // Update Chess instance
    setIsReviewing(true); // Update UI state
    setAnalysisLoading(true);

    const analyzedGame = await analyzeGame(game, 20);

    const whiteUsername = game.pgn.match(/\[White "(.+?)"\]/)?.[1] || "White";
    const blackUsername = game.pgn.match(/\[Black "(.+?)"\]/)?.[1] || "Black";

    updateGameState({
      analysis: analyzedGame.analysis || [],
      playerColor: game.playerColor,
      usernames: { white: whiteUsername, black: blackUsername },
//      openingPrepEndIndex: null,
//      sidelines: [],
    });

    setAnalysisComplete(true);
//    console.log("Analyzed Game:", analyzedGame);
  } catch (error) {
    console.error("Error analyzing game:", error);
  } finally {
    setAnalysisLoading(false);
  }
};

// Helper function to parse PGN into individual moves
const parsePGNMoves = (pgn) => {
//  console.log("Extracting moves from PGN...");

  const sanitizedPGN = pgn
    .split(/\n|\r/)
    .filter((line) => !line.startsWith("[") && line.trim() !== "") // Ignore metadata and blank lines
    .join(" ")
    .replace(/\{[^}]*\}/g, "") // Remove comments {...}
    .replace(/\s+/g, " ") // Replace multiple spaces with a single space
    .trim();

  const moves = [];
  const tokens = sanitizedPGN.split(/\s+/);

  tokens.forEach((token) => {
    if (/^\d+\./.test(token)) return; // Skip move numbers
    if (!["*", "1-0", "0-1", "1/2-1/2"].includes(token)) {
      moves.push(token.trim());
    }
  });

//  console.log("Extracted moves:", moves);
  return moves.filter((move) => move !== ""); // Remove any residual empty moves
};

  const handleCustomGameSubmit = () => {
    if (!customPGN.trim()) {
      alert("Please enter a valid PGN.");
      return;
    }

    const customGame = {
      pgn: customPGN,
      playerColor: customPlayerColor,
    };

    handleGameSelection(customGame);
  };

  const handleRatingChange = (moveIndex, rating, comment) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [moveIndex]: { rating, comment },
    }));
  };


const handleMoveChange = (newIndex) => {
  if (typeof newIndex !== "number") return;

//  console.log(`Navigating to move index: ${newIndex}`);
  setSelectedMoveIndex(newIndex); // Update the current move index

  // Get the move history up to the new index
  const moves = reviewingGame.history({ verbose: true }).slice(0, newIndex + 1);

  const tempGame = new Chess(); // Temporary Chess instance to replay moves
  moves.forEach((move) => {
    tempGame.move(move.san); // Apply each move to reach the desired state
  });

  const newFen = tempGame.fen(); // Get FEN for the new position
//  console.log(`New FEN for move index ${newIndex}: ${newFen}`);
  setDisplayFen(newFen); // Update the display FEN state
};

const openInLichess = () => {
  const fen = displayFen;
  const encodedPgn = encodeURIComponent(fen);
  const lichessUrl = `https://lichess.org/analysis/standard/${encodeURIComponent(fen)}?openBrowser=true`;
  window.open(lichessUrl, '_blank');
};

  const handleMoveData = (data) => {
    setMoveData(data); // Store the move data in state
  };


const handlePromotion = (piece) => {
  console.log('Handling promotion...');
  if (promotionDetails) {
    const { pieceSquare, square } = promotionDetails;

    // Temporary game to manage the FEN and moves
    const tempGame = new Chess();
    tempGame.load(displayFen);

    const move = tempGame.move({ from: pieceSquare, to: square, promotion: piece });

    if (!move) {
      console.error("Promotion move could not be applied.");
      return;
    }

    // Check if in a sideline
    if (selectedSidelineIndex !== null && selectedSidelineMoveIndex !== null) {
      console.log("Applying promotion in sideline...");

      handleSidelineMove({
        move,
        tempGame,
        displayFen,
        selectedMoveIndex,
        selectedSidelineIndex,
        selectedSidelineMoveIndex,
        setSelectedSidelineIndex,
        setSelectedSidelineMoveIndex,
        setSidelines,
      });
    } else {
      console.log("Applying promotion in mainline...");
      handleMove(pieceSquare, square, piece);
    }

    // Update state after promotion
    setPromotionOpen(false);
    setPromotionDetails(null);
    setDisplayFen(tempGame.fen());
    console.log("Promotion handled successfully.");
  }
};

const handleMove = (from, to, piece=null) => {

  const move = reviewingGame.move({ from, to, promotion: piece });

  if (move) {
    // Update display and states if the move is valid
    setDisplayFen(reviewingGame.fen());
    setSelectedMoveIndex((prevIndex) => prevIndex + 1);
    setSquareStyles({}); // Reset square styles
    setPieceSquare(""); // Clear the selected square
  } else {
//    alert("Invalid move!"); // Handle invalid moves gracefully
  }
};

////////////////////////////////////// Arrow drawing for engine

const drawArrows = (moves) => {
  const canvas = canvasRef.current;
  if (!canvas) {
    console.error("Canvas not found");
    return;
  }

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    console.error("Canvas context not available");
    return;
  }

  // Clear the entire canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Helper function to reverse square names for black orientation
  const reverseSquare = (square) => {
    if (!square || square.length !== 2) return square; // Invalid square, return as is
    const files = "abcdefgh";
    const ranks = "12345678";
    const reversedFile = files[7 - files.indexOf(square[0])];
    const reversedRank = ranks[7 - ranks.indexOf(square[1])];
    return reversedFile + reversedRank;
  };

  // Draw each arrow in the moves array
  moves.forEach((move) => {
    if (move?.from && move?.to && move?.color) {
      const fromSquare = orientation === "Black" ? reverseSquare(move.from) : move.from;
      const toSquare = orientation === "Black" ? reverseSquare(move.to) : move.to;

      drawEngineArrow(canvasRef, fromSquare, toSquare, move.color);
    }
  });
};

  // React to moveData changes to draw arrows
  useEffect(() => {
    drawArrows(moveData); // Process and draw arrows when moveData updates
    console.log('Drawing: ' + JSON.stringify(moveData));
  }, [moveData, orientation]);

useEffect(() => {
    setupCanvas();
}, [chessboardSize, checkingWithAi]);

const setupCanvas = () => {
  const canvas = canvasRef.current;

  // Using the latest chessboard size passed from the parent component
  const size = chessboardSize;

  if (canvas) {
    canvas.width = size; // Directly manipulating canvas size
    canvas.height = size;
    canvas.style.width = `${size}px`; // Adjusting style for visual size
    canvas.style.height = `${size}px`;

    // Accessing the canvas context to draw or clear it
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas for new drawing
    ctx.fillStyle = 'rgba(50, 50, 50, 0)'; // Setting a fill style for demonstration
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Filling the canvas with the chosen style
  } else {
//    console.log('Canvas not found');
  }
};

////////////////////////////////////// Arrow drawing for engine
////////////////////////////////////// Move history scroll

const scrollToSelectedMove = () => {
  if (moveHistoryRef.current) {
    const selectedElement = moveHistoryRef.current.querySelector(".selected");
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest", // Scrolls only within the container
        inline: "nearest",
      });
    }
  }
};

  useEffect(() => {
    scrollToSelectedMove();
  }, [selectedMoveIndex]);

const scrollToSelectedSideline = () => {
  if (sidelineRef.current) {
    const selectedMove = sidelineRef.current.querySelector(".selected");
    if (selectedMove) {
      selectedMove.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest", });
    }
  }
};

  useEffect(() => {
    scrollToSelectedSideline();
  }, [selectedSidelineMoveIndex]);

////////////////////////////////////// Move history scroll

  return (
    <div className="game-analysis-container">
      <>
        {isMobile && <EnableScrollOnPage />}
      </>
      
      <JoyrideWrapper
        steps={gameAnalysisRide}
        run={isTourOpen}
        onFinish={() => setIsTourOpen(false)} // Close tour when finished
      />
      <JoyrideWrapper
        steps={gameAnalysisRide2}
        run={isTour2Open}
        onFinish={() => setIsTour2Open(false)} // Close tour when finished
      />
      {!isReviewing && (
        <>
            {activeSidebar === "LoadGame" && (
                <LoadGameSidebar user={user} onGameLoad={handleLoadGame} />
              )}
              {activeSidebar === "GameRetriever" && (
                <GameRetriever user={user} onGameSelect={handleGameSelection} />
              )}
           <div className="game-analysis-content">
            <h1>Analyze Your Games</h1>
              <div className="toggle-sidebar-buttons">
                <button
                  className={`average-button ${activeSidebar === "LoadGame" ? "active" : ""}`}
                  onClick={() => setActiveSidebar("LoadGame")}
                >
                  Load Saved Games
                </button>
                <button
                  className={`average-button ${activeSidebar === "GameRetriever" ? "active" : ""}`}
                  onClick={() => setActiveSidebar("GameRetriever")}
                >
                  Retrieve Games
                </button>
              </div>
            <p>Select a game from the sidebar or enter a custom PGN below.</p>
            <div className="custom-pgn-entry">
              <textarea
                placeholder="Paste your PGN here..."
                value={customPGN}
                onChange={(e) => setCustomPGN(e.target.value)}
                rows={6}
              />
              <p>You Played as:</p>
              <div className="color-selection">
                <label>
                  <input
                    type="radio"
                    name="playerColor"
                    value="White"
                    checked={customPlayerColor === "White"}
                    onChange={(e) => setCustomPlayerColor(e.target.value)}
                  />
                  White
                </label>
                <label>
                  <input
                    type="radio"
                    name="playerColor"
                    value="Black"
                    checked={customPlayerColor === "Black"}
                    onChange={(e) => setCustomPlayerColor(e.target.value)}
                  />
                  Black
                </label>
              </div>
              <button
                className="average-button"
                onClick={handleCustomGameSubmit}
                disabled={analysisLoading}
              >
                {analysisLoading ? "Analyzing..." : "Analyze Custom Game"}
              </button>
            </div>
          </div>
        </>
      )}
      {isReviewing && (
        <div className="game-layout">
        {promotionOpen && (
          <div className='promotion-modal-container'>
            <PromotionModal
              onPromote={handlePromotion}
              pieces={['q', 'r', 'n', 'b']}
              color={playerColor === 'White' ? 'w' : 'b'}
            />
          </div>
        )}
          <div className="chessboard-container">
            <canvas ref={canvasRef} style={{ zIndex: 100, position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}></canvas>
            <ChessboardCustom
              position={displayFen}
              orientation={orientation}
              squareStyles={squareStyles}
              degreeOfBlindness='Normal'
              ref={chessboardRef}
              updateChessboardSize={setChessboardSize}
                onSquareClick={(square) =>
                  handleSidelineSquareClick(
                    square,
                    setPromotionOpen,
                    promotionDetails,
                    setPromotionDetails,
                    displayFen,
                    setDisplayFen,
                    pieceSquare,
                    setPieceSquare,
                    restoreSquareStyles,
                    squareStyles,
                    setSquareStyles,
                    setSidelines,
                    selectedSidelineIndex,
                    setSelectedSidelineIndex,
                    selectedSidelineMoveIndex,
                    setSelectedSidelineMoveIndex,
                    selectedMoveIndex,
                    sidelines
                  )
                }
                onDrop={({ sourceSquare, targetSquare }) => {
                  onDropWithSidelines({
                    sourceSquare,
                    targetSquare,
                    game: reviewingGame,
                    setPromotionDetails,
                    setPromotionOpen,
                    displayFen,
                    setDisplayFen,
                    selectedMoveIndex,
                    selectedSidelineIndex,
                    setSelectedSidelineIndex,
                    selectedSidelineMoveIndex,
                    setSelectedSidelineMoveIndex,
                    setSidelines,
                  });
                }}
            />
          </div>
          <div className="move-history-container" ref={moveHistoryRef}>
             {checkingWithAi && (
              <EngineMoves fen={displayFen} onMoveData={handleMoveData} game={reviewingGame} />
             )}
            {isReviewing && renderMoveHistory(
              {
                state: {
                  history: reviewingGame.history({ verbose: true }),
                  fen: reviewingGame.fen(),
                  selectedMoveIndex: selectedMoveIndex,
                  isGameAnalysis: true,
                  checkingWithAi: checkingWithAi,
                },
                setDisplayFen,
                setSquareStyles,
                setSelectedMoveIndex,
                handleHint,
                openInLichess,
                setShowSaveModal,
                flipBoard,
                moveHistoryRef,
                sidelineRef,
                setSidelines,
                selectedSidelineIndex,
                setSelectedSidelineIndex,
                selectedSidelineMoveIndex,
                setSelectedSidelineMoveIndex

              },
            reviewingGame.history({ verbose: true }),
            (data) => data,
            sidelines,
            true
          )}
          </div>
            <PromptBox
              moveIndex={selectedMoveIndex} // Current move index
              onMoveChange={handleMoveChange}
              game={reviewingGame}
              onAiCheckChange={handleAiCheckChange}
              sidelines={sidelines}
              playerColor={playerColor}
              onlyUserMoves={onlyUserMoves}
              setOnlyUserMoves={setOnlyUserMoves}
              setShowSaveModal={setShowSaveModal}
              flagsForReview={flagsForReview}
              setFlagsForReview={setFlagsForReview}
            />
        </div>
      )}
      {showSaveModal && (
      <div className="modal-overlay">
          <div className="modal-content">
            <h2>Save Game</h2>
            <label>
              Game Name:
              <input
                type="text"
                value={gameName}
                onChange={(e) => setGameName(e.target.value)}
              />
            </label>
            <div className="modal-actions">
              <button className='average-button' onClick={handleSave} disabled={isSaving}>
                {isSaving ? "Saving..." : "Save"}
              </button>
              <button className='average-button' onClick={onClose}>Cancel</button>
            </div>
          </div>
        </div>
    )}
    </div>
  );
};

export default GameAnalysis;
