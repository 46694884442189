// ChildProtectionPolicy.js
import React from "react";
import EnableScrollOnPage from "../../Integrations/EnableScroll";

const ChildProtectionPolicy = () => {
  return (
<div className="help-container">
    <EnableScrollOnPage />
  <h1>Child Protection & Safety Policy</h1>
  <p>
    The Exchange is committed to maintaining a safe and respectful community for all users.
    We enforce a strict zero-tolerance policy regarding content, behavior, or communication
    that may involve child sexual abuse or exploitation (CSAE).
  </p>

  <section className="help-section">
    <h2>Reporting and Moderation</h2>
    <p>
      Users can report inappropriate content or conduct in-app using the built-in report function,
      or by contacting us directly at{' '}
      <a href="mailto:contact@trainchess.net" className="further-help-link">
        support@trainchess.net
      </a>.
    </p>
    <p>
      Every report is reviewed promptly by our moderation team. When necessary, we take immediate
      action, which may include account suspension, content removal, and notification to law enforcement.
    </p>
  </section>

  <section className="help-section">
    <h2>Age Requirements</h2>
    <p>
      The Exchange is intended for users aged 13 and older. 
    </p>
    <p>
      We use a combination of proactive moderation, keyword detection, and user reports to flag
      potential abuse or misuse and take necessary actions to prevent harm.
    </p>
  </section>

  <div className="exchange-footer">
    <p>Last updated: March 26, 2025</p>
  </div>
</div>
  );
};

export default ChildProtectionPolicy;
