export const moveTrainerRide = [
    {
      disableBeacon: true,
      placement: 'center',
      target: '.file-structure-sidebar',
      title: 'Welcome to Opening Trainer!',
      content: 'On this page you are able to develop and train your very own opening repertoire. Getting started is simple.',
    },
    {
      disableBeacon: true,
      placement: 'right-start',
      target: '.folder-icon',
      content: 'Let\'s start by creating a folder to organize all of your opening variations. What opening do you want to start with?',
    },
  ];

  export const moveTrainerRide2 = [
    {
      disableBeacon: true,
      placement: 'right-start',
      target: '.plus-icon',
      content: 'Once you have selected a folder you will be able to add lines using this button.',
    },
    {
      disableBeacon: true,
      placement: 'left',
      target: '.chessboard-container',
      content: 'You will input moves here on the board or you can add variations by pasting a pgn.',
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.note-container',
      content: 'You can add notes and observations here to help you remember and understand your opening.',
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.bottom-buttons',
      content: 'Once you have your line ready to save click a floppy disk save button that will appear here to add it to your folder.',
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.test-button',
      content: 'With variations added to a folder you can click "TRAIN" to start practicing your repertoire!',
    },
    {
      disableBeacon: true,
      placement: 'center',
      target: '.file-structure-sidebar',
      title: 'Good Luck!',
      content: 'Have fun building your game winning repertoires!',
    },
  ];

    export const findForkRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout', // CSS selector for the container
            content: 'Sharpen your ability to spot forks, helping you either execute or defend against them effectively in games.',
            title: 'Welcome to Find the Fork!',
        },
        {
            disableBeacon: true,
            target: '.chessboard-container',
            content: 'Simply click the square where the white piece should move to attack both of black\'s pieces.',
        },
        {
            disableBeacon: true,
            target: '.puzzle-button',
            content: 'Feel free to practice, but when you\'re ready hit "Start Game" to see how many puzzles you can solve in 60 seconds.',
        },
        {
            disableBeacon: true,
            target: '.leaderboard',
            placement: 'top',
            content: 'Here, you’ll see the all-time and monthly records. Compete to see your name here!',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.feedback-container',
            content: (
                <>
                    For any additional questions you can access the{' '}
                    <a href="/findthefork/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a>.
                </>
            ),
        },
    ];

    export const coordinateRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout',
            content: 'Improve your ability to recognize chessboard coordinates with this training game.',
            title: 'Welcome to the Coordinate Trainer!',
        },
        {
            disableBeacon: true,
            target: '.chessboard-container',
            content: 'Click the square that matches the coordinate given to you. Try to be as quick and accurate as possible!',
        },
        {
            disableBeacon: true,
            target: '.puzzle-button',
            content: 'You can practice freely. When you’re ready, click "Start Game" to begin a 60-second challenge. See how many you can get correct in the time frame.',
        },
        {
            disableBeacon: true,
            placement: 'top',
            target: '.leaderboard',
            content: 'Check out the leaderboard to see the top scores! Compete for both monthly and all-time records.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.feedback-container',
            content: (
                <>
                    Need additional help? Visit the{' '}
                    <a href="/coordinatetrainer/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a>.
                </>
            ),
        },
    ];

    export const coordinateTypeRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout',
            content: 'Improve your ability to recognize chessboard coordinates with this training game.',
            title: 'Welcome to the Coordinate Trainer!',
        },
        {
            disableBeacon: true,
            target: '.move-input',
            content: 'Type the coordinate that corresponds with the highlighted square. Try to be as quick and accurate as possible!',
        },
        {
            disableBeacon: true,
            target: '.puzzle-button',
            content: 'You can practice freely. When you’re ready, click "Start Game" to begin a 60-second challenge. See how many you can get correct in the time frame.',
        },
        {
            disableBeacon: true,
            placement: 'top',
            target: '.leaderboard',
            content: 'Check out the leaderboard to see the top scores! Compete for both monthly and all-time records.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.feedback-container',
            content: (
                <>
                    Need additional help? Visit the{' '}
                    <a href="/coordinatetrainer/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a>.
                </>
            ),
        },
    ];

    export const tacticsRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout',
            content: 'Here you\'ll find unlimited tactical puzzles to get you familiar with common chess patterns.',
            title: 'Welcome to the Tactics Trainer!',
        },
        {
            disableBeacon: true,
            target: '.chessboard-container',
            content: 'You will be provided with a position on the board. Your job is to find the best move.',
        },
        {
            disableBeacon: true,
            target: '.rating-box',
            content: (
                <>
                    Your puzzle rating is adjusted with our unique algorithm which targets puzzles of the correct difficulty for you to improve.
                    More on that <a href="/ratingmetrics" target="_blank">here</a>.
                </>
            ),
        },
        {
            disableBeacon: true,
            target: '.accuracy-box',
            content: 'A moving average of your puzzle accuracy is shown here. Part of the algorithm involves seeking an accuracy of 85%, a scientifically proven sweet spot.',
        },
        {
            disableBeacon: true,
            target: '.time-controls',
            content: 'Adjust the time allowed for each puzzle to simulate time pressure.',
        },
        {
            disableBeacon: true,
            target: '.endgame-filter',
            content: 'Or filter to positions with fewer pieces.',
        },
        {
            disableBeacon: true,
            target: '.puzzle-button-row',
            content: 'You can give up and reveal the solution, analyze the previous puzzle or if you need a nudge in the right direction try a board awareness quiz to jumpstart your analysis',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout',
            content: (
                <>
                    Need additional help? Visit the{' '}
                    <a href="/puzzles/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a>.
                </>
            ),
         },
    ];

    export const calculationRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout',
            content: 'Practice analyzing and solving positions that haven\'t yet been reached on the board, an essential and underpracticed skill in chess. Learning the flow of this exercise can be a bit tricky at first, so pay attention.',
            title: 'Welcome to Calculation Practice!',
        },
        {
            disableBeacon: true,
            target: '.chessboard-container',
            content: 'You will be provided with a position on the board. This is NOT the position you are trying to solve.',
        },
        {
            disableBeacon: true,
            target: '.table-container',
            content: 'Your first task is to play out these moves in your head. Hover over each move for assistance in visualizing the line. The resulting position is the position you need to analyze.',
        },
        {
            disableBeacon: true,
            target: '.chessboard-container',
            content: 'With that resulting position in mind, find the best move. When you think you have it click the \'from square\' and \'to square\' to move the piece.',
        },
        {
            disableBeacon: true,
            target: '.rating-box',
            content: (
                <>
                    Your puzzle rating is adjusted with our unique algorithm which targets puzzles of the correct difficulty for you to improve.
                    More on that <a href="/ratingmetrics" target="_blank">here</a>.
                </>
            ),
        },
        {
            disableBeacon: true,
            target: '.accuracy-box',
            content: 'A moving average of your puzzle accuracy is shown here. Part of the algorithm involves seeking an accuracy of 85%, a scientifically proven sweet spot.',
        },
        {
            disableBeacon: true,
            target: '.puzzle-button-row',
            content: 'You can give up and reveal the solution, analyze the previous puzzle or adjust the number of moves ahead you want to look.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout',
            content: (
                <>
                    Still confused? Visit the{' '}
                    <a href="/calculationpuzzles/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a> for a more in depth explanation.
                </>
            ),
         },
    ];

    export const blindfoldPuzzleRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.chess-piece-table',
            content: 'Improved your visualization skills in bite-sized tactical blindfold positions.',
            title: 'Welcome to Blindfold Puzzles!',
        },
        {
            disableBeacon: true,
            target: '.chess-piece-table',
            content: 'You are given the locations of several pieces. Your task is to visualize this position on a mental board and evaluate candidate moves to arrive at the solution.',
        },
        {
            disableBeacon: true,
            target: '.blindfold-form',
            content: 'Test your solution by submitting the move in algebraic notation.',
        },
        {
            disableBeacon: true,
            target: '.move-history',
            content: 'Moves will be shown here until the puzzle is complete.',
        },
        {
            disableBeacon: true,
            target: '.blindfold-info-container',
            content: (
                <>
                    Your puzzle rating is adjusted with our unique algorithm which targets puzzles of the correct difficulty for improvement.
                    More on that <a href="/ratingmetrics" target="_blank">here</a>.
                </>
            ),
        },
        {
            disableBeacon: true,
            target: '.toggle-board-button',
            content: 'If you are stuck and need to check the position for clarity you can peek at the board by clicking this button. Note: this will result in a failed puzzle.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.chess-piece-table',
            content: (
                <>
                    Still confused? Visit the{' '}
                    <a href="/blindfoldpuzzles/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a> for a more in depth explanation.
                </>
            ),
         },
    ];


    export const gameAnalysisRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-analysis-container',
            content: 'Review your games, identify real game misunderstandings and learn from your mistakes.',
            title: 'Welcome to Game Analysis!',
        },
        {
            disableBeacon: true,
            target: '.toggle-manage-button',
            content: 'Start by connecting to your Chess.com or Lichess account for easy access to your recently played games.',
        },
        {
            disableBeacon: true,
            target: '.custom-pgn-entry',
            content: 'Or paste your pgn here to begin analyzing.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-analysis-content',
            content: 'Let\'s get started by selecting a game!',
        },
    ];

    export const gameAnalysisRide2 = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.chessboard-container',
            content: 'Your selected game is set up on the chessboard. Let\'s first go through without the engine. This will help you identify the gaps of understanding in your game.',
            title: 'Game Review!',
        },
        {
            disableBeacon: true,
            target: '.prompt-box',
            content: 'You will be given prompts to catalyze your analysis and test your understanding.',
        },
        {
            disableBeacon: true,
            target: '.history-controls',
            content: 'Navigate through the game move by move and follow the prompts for each move.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.chessboard-container',
            content: (
                <>
                    Work through your game and when you're ready click "Check with AI" to identify any misunderstandings to work through again. Still confused? Visit the{' '}
                    <a href="/gameanalysis/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a> for a more in depth explanation.
                </>
            ),
         },
    ];

    export const EditStudyPlanRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.edit-container',
            content: 'Make a study plan that fits your schedule and goals.',
            title: 'Let\'s Train Chess!',
        },
        {
            disableBeacon: true,
            target: '.edit-input',
            content: 'Enter your total goal study time per week.',
        },
        {
            disableBeacon: true,
            target: '.study-plan-section',
            content: 'Enter the percentage of time you want to spend on each study type.',
        },
        {
            disableBeacon: true,
            target: '.save-study-plan',
            content: 'When you\'re ready, click "Save Study Plan" and we\'ll take care of the rest',
        },
        // {
        //     disableBeacon: true,
        //     placement: 'center',
        //     target: '.chessboard-container',
        //     content: (
        //         <>
        //             Work through your game and when you're ready click "Check with AI" to identify any misunderstandings to work through again. Still confused? Visit the{' '}
        //             <a href="/gameanalysis/help" target="_blank" rel="noopener noreferrer">
        //                 help page
        //             </a> for a more in depth explanation.
        //         </>
        //     ),
        //  },
    ];

    export const TrainRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.train-container',
            title: 'Welcome to Train Chess!',
            content: 'This is your personalized chess training hub. Let’s walk through how to use this page.',
        },
        {
            disableBeacon: true,
            placement: 'top',
            target: '.study-plan-container',
            content: 'Here is your study plan overview. It shows your study goals.',
        },
        {
            disableBeacon: true,
            placement: 'top',
            target: '.edit-button',
            content: 'You can edit your plan at any point.',
        },
        {
            disableBeacon: true,
            placement: 'top',
            target: '.progress-bar-container',
            content: 'This progress bar tracks how much of your weekly study goal you’ve completed.',
        },
        {
            disableBeacon: true,
            placement: 'top',
            target: '.next-up-box',
            content: 'This section shows the next study block in your plan. All you need to do is click "TRAIN" to start!',
        },
    ];