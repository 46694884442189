import React, { useContext, useState, useRef, useEffect } from "react";
import { ChevronUp, ChevronDown, X, Minus } from "lucide-react";
import TrainingContext from "../Integrations/TrainingContext";

const TrainingOverlay = () => {
  const { timeLeft, isRunning, handleEndTraining, studyPlan, handleQuitTraining, currentIndex, pendingNavigation, pauseTraining, resumeTraining, continueTraining } = useContext(TrainingContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const overlayRef = useRef(null);
  const [position, setPosition] = useState({ x: 20, y: 20 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const currentTrainingName = studyPlan[currentIndex]?.name || "Training";
  const currentTrainingNote = studyPlan[currentIndex]?.note || "";

  // Handle mouse drag
  const handleMouseDown = (e) => {
    setDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({ x: e.clientX - offset.x, y: e.clientY - offset.y });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  if (!isRunning) return null;

  return (
    <>
      {isHidden && (
        <div
          className="overlay-tab"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            color: "white",
            padding: "8px 12px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: "bold",
            zIndex: 9999,
          }}
          onClick={() => setIsHidden(false)}
        >
          ▲
        </div>
      )}

      {!isHidden && (
        <div
          ref={overlayRef}
          className={`overlay-container ${dragging ? "dragging" : ""}`}
          style={{ left: `${position.x}px`, top: `${position.y}px` }}
          onMouseDown={handleMouseDown}
        >
          {/* Header */}
          <div className="overlay-header">
            <strong>{currentTrainingName}</strong>
            <div>
            <button className="expand-button" onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
              </button>
              <button className="hide-button" onClick={() => setIsHidden(true)}>
                <Minus size={18} />
              </button>
              <button className="hide-button" onClick={handleQuitTraining}>
                <X size={18} />
              </button>
            </div>
          </div>

          {isExpanded && (
            <>
              {/* Timer */}
              <div className="overlay-timer">
                {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, "0")}
              </div>

              {/* Show "Continue Training" button if pending */}
              {pendingNavigation ? (
                <div>
                  <p> {currentTrainingNote}</p>
                  <button onClick={continueTraining} className="average-button">
                    Navigate to {currentTrainingName}
                  </button>
                </div>
              ) : timeLeft === 0 ? (
                // 👉 NEW: Show Finish button if timeLeft is 0
                <div className="overlay-buttons">
                  <button onClick={handleEndTraining} className="average-button">
                    Finish Training
                  </button>
                </div>
              ) : (
                <div className="overlay-buttons">
                  <button onClick={pauseTraining} className="stop-button">Pause</button>
                  <button onClick={resumeTraining} className="next-button">Resume</button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TrainingOverlay;
