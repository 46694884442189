import React from 'react';
import '../../styles/help.css'; // Reusing the same style file for consistency
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const PrivacyPolicyPage = () => {
  return (
    <div className="help-container">
      <EnableScrollOnPage />
      <h1>Privacy Policy for The Exchange</h1>
      <p><strong>Effective Date:</strong> January 22, 2025</p>
      <p><strong>Last Updated:</strong> January 22, 2025</p>

      <p>Welcome to <em>The Exchange</em>, a mobile app provided by <strong>Train Chess</strong> (“we,” “us,” or “our”). This Privacy Policy describes how we collect, use, and protect your personal information when you use our app.</p>

      {/* Information We Collect */}
      <section className="policy-section">
        <h2>1. Information We Collect</h2>
        <p>When you use <em>The Exchange</em>, we collect the following data to provide and improve our services:</p>
        <ul>
          <li><strong>Account Information:</strong> Full name, email address, username, and profile picture (required).</li>
          <li><strong>Authentication:</strong> Managed securely through Firebase Authentication.</li>
          <li><strong>User-Generated Content:</strong> Chess puzzles, posts, comments, and interactions (e.g., likes).</li>
          <li><strong>Device Information:</strong> Device model, operating system version, and unique device identifiers.</li>
          <li><strong>Usage Data:</strong> Interactions within the app, such as puzzle creation, likes, and comments.</li>
          <li><strong>Communication Data:</strong> Messages or support inquiries sent to us via <a href="mailto:contact@trainchess.net">contact@trainchess.net</a>.</li>
        </ul>
      </section>

      {/* How We Use Your Information */}
      <section className="policy-section">
        <h2>2. How We Use Your Information</h2>
        <p>We use your data to:</p>
        <ul>
          <li>Provide and enhance the app experience.</li>
          <li>Facilitate user interactions, such as posting puzzles, commenting, and liking content.</li>
          <li>Maintain security and prevent fraudulent activities.</li>
          <li>Respond to user support inquiries.</li>
        </ul>
      </section>

      {/* Data Storage and Security */}
      <section className="policy-section">
        <h2>3. Data Storage and Security</h2>
        <p>Your data is securely stored using <strong>Google Firebase</strong>, which provides encryption and security measures to protect your information. We follow industry best practices to safeguard your data.</p>
        <p>If you choose to delete your account, all associated data (including profile, posts, and interactions) will be permanently removed.</p>
      </section>

      {/* Sharing of Information */}
      <section className="policy-section">
        <h2>4. Sharing of Information</h2>
        <p>We do not sell or share your personal data with third parties for marketing purposes. However, we may share data with:</p>
        <ul>
          <li><strong>Service Providers:</strong> Firebase and other essential third-party tools used to operate the app.</li>
          <li><strong>Legal Purposes:</strong> If required by law to comply with legal obligations or law enforcement.</li>
        </ul>
      </section>

      {/* User Rights and Data Deletion */}
      <section className="policy-section">
        <h2>5. User Rights and Data Deletion</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access and update your profile information through the app settings.</li>
          <li>Request account deletion, which will remove all associated data permanently.</li>
          <li>Contact us at <a href="mailto:contact@trainchess.net">contact@trainchess.net</a> for any privacy-related questions.</li>
        </ul>
      </section>

      {/* Children's Privacy */}
      <section className="policy-section">
        <h2>6. Children's Privacy</h2>
        <p><em>The Exchange</em> is not intended for children under <strong>13 years of age</strong>. We do not knowingly collect data from children under 13. If we become aware that such data has been collected, we will take immediate steps to delete it.</p>
        <p>If you believe that we have collected such data, please contact us at <a href="mailto:contact@trainchess.net">contact@trainchess.net</a>.</p>
      </section>

      {/* Changes to Privacy Policy */}
      <section className="policy-section">
        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any significant changes will be communicated within the app or via email. We encourage users to review this policy periodically.</p>
      </section>

      {/* Contact Us */}
      <section className="policy-section">
        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <p><strong>Email:</strong> <a href="mailto:contact@trainchess.net">contact@trainchess.net</a></p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
