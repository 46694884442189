import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function Minigames() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="home-page">
            <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Chess Minigames</h1>
            <p style={{ textAlign: 'center', marginBottom: '30px', fontSize: '1.2rem' }}>
                Explore fun and interactive chess minigames designed to improve your skills in specific areas of the game.
            </p>

            {/* Bonus Tools Section */}
            <div className="bonus-section">
                <div className="bonus-card" onClick={() => handleNavigation('/coordinatetrainer')}>
                    <h3>Coordinate Trainer</h3>
                    <p>Practice quickly clicking notated squares to help you learn chess notation. </p>
                </div>
                <div className="bonus-card" onClick={() => handleNavigation('/coordinatetrainer2')}>
                    <h3>Coordinate Trainer 2</h3>
                    <p>Practice naming highlighted squares in proper notation to help you learn chess notation. </p>
                </div>
                <div className="bonus-card" onClick={() => handleNavigation('/findthefork')}>
                    <h3>Find the Fork</h3>
                    <p>Learn to recognize forking patterns by quickly solving targeted exercises.</p>
                </div>
            </div>
        </div>
    );
}

export default Minigames;
