import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { useUser } from "../Integrations/UserContext";
import EnableScrollOnPage from "./EnableScroll"; // Import the scroll enabler
import "../styles/train.css"; // Import CSS
import JoyrideWrapper from '../Integrations/JoyrideWrapper';
import { EditStudyPlanRide } from '../Integrations/Joyrides';

const DEFAULT_STUDY_PLAN = [
  { name: "Play Games", percentage: 20, path: "/playgames", note: "" },
  { name: "Analyze Games", percentage: 30, path: "/gameanalysis", note: "" },
  { name: "Endgames", percentage: 20, path: "/endgames", note: "" },
  { name: "Puzzles", percentage: 25, path: "/puzzles", note: "" },
  { name: "Openings", percentage: 5, path: "/openingtrainer", note: "" },
];

const MAX_PERCENTAGE = 100;

const EditStudyPlan = ({ setStudyPlan, setStudyBlocks, setTotalHours, totalHours, setEditing }) => {
  const { user } = useUser();
  const [studyPlan, setLocalStudyPlan] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    if (user) loadStudyPlan();
  }, [user]);

  useEffect(() => {
      const checkIfFirstVisit = async () => {
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
              const data = userDoc.data();
              if (!data.hasSeenEditStudyPlan) {
                  setIsTourOpen(true); // Start tour if it’s the user’s first visit
                  await setDoc(userRef, { hasSeenEditStudyPlan: true }, { merge: true });
              }
          }
      };

      if (user) {
          checkIfFirstVisit();
      }
  }, [user]);

const loadStudyPlan = async () => {
  if (!user?.uid) return;

  const planRef = doc(collection(db, "users", user.uid, "train"), "studyPlan");
  const planSnap = await getDoc(planRef);

  if (planSnap.exists()) {
    const data = planSnap.data();
    setLocalStudyPlan(data.studyPlan || DEFAULT_STUDY_PLAN);
    setTotalHours(data.totalHours || 5);
    updateTotalPercentage(data.studyPlan || DEFAULT_STUDY_PLAN);
  } else {
    setLocalStudyPlan(DEFAULT_STUDY_PLAN);
    setTotalHours(5);
    updateTotalPercentage(DEFAULT_STUDY_PLAN);
  }
};


  const updateTotalPercentage = (updatedPlan) => {
    const total = updatedPlan.reduce((sum, item) => sum + item.percentage, 0);
    setTotalPercentage(total);
  };

  const updateSection = (index, field, value) => {
    setLocalStudyPlan((prevPlan) => {
      const updatedPlan = [...prevPlan];
  
      if (field === "path") {
        const pathToNameMap = {
          "/playgames": "Play Games",
          "/gameanalysis": "Analyze Games",
          "/endgames": "Endgames",
          "/puzzles": "Puzzles",
          "/calculationpuzzles": "Calculation Puzzles",
          "/blindfoldpuzzles": "Blindfold Puzzles",
          "/openingtrainer": "Openings",
        };
        updatedPlan[index] = {
          ...updatedPlan[index],
          path: value,
          name: pathToNameMap[value],
        };
      } else {
        updatedPlan[index] = {
          ...updatedPlan[index],
          [field]: value,
        };
      }
  
      updateTotalPercentage(updatedPlan); // ✅ Use updated state, not stale one
      return updatedPlan;
    });
  };
  
  const addSection = () => {
    if (studyPlan.length >= 10) return alert("Maximum 10 sections allowed!");

    const newPlan = [...studyPlan, { name: "New Section", percentage: 10, path: "/puzzles" }];
    setLocalStudyPlan(newPlan);
    updateTotalPercentage(newPlan);
  };

  const removeSection = (index) => {
    if (studyPlan.length <= 1) return alert("You need at least one study section!");

    const newPlan = studyPlan.filter((_, i) => i !== index);
    setLocalStudyPlan(newPlan);
    updateTotalPercentage(newPlan);
  };

//const generateStudyBlocks = (studyPlan) => {
//  console.log("Generating study blocks...");
//  let blocks = [];
//
//  // Step 1: Convert percentages into normalized ratios
//  let totalPercentage = studyPlan.reduce((sum, section) => sum + section.percentage, 0);
//  let ratios = studyPlan.map((section) => ({
//    ...section,
//    ratio: section.percentage / totalPercentage, // Normalize ratio
//  }));
//
//  console.log("Normalized study plan ratios:", ratios);
//
//  // Step 2: Find the smallest ratio and scale it to 30 minutes
//  let minRatio = Math.min(...ratios.map((s) => s.ratio));
//  let baseTime = 30 / minRatio; // Base total rotation time
//
//  console.log(`Base rotation time calculated: ${baseTime.toFixed(2)} minutes`);
//
//  // Step 3: Generate sessions and store in a list
//  let sessionMap = {};
//  ratios.forEach((section) => {
//    let sessionMinutes = section.ratio * baseTime;
//    let sessionList = []; // Temporary list for appending sessions
//
//    console.log(`\n🔹 Processing: ${section.name}`);
//    console.log(`   ➡ Total Minutes for ${section.name}: ${sessionMinutes.toFixed(2)} (before splitting)`);
//
//    while (sessionMinutes > 0) {
//      let numSplits = Math.ceil(sessionMinutes / 60); // Decide number of splits
//      let duration = Math.round((sessionMinutes / numSplits) / 5) * 5; // Round to nearest 5
//
//      if (duration < 30) duration = 30; // Ensure at least 30 minutes
//
//      // Adjust last session if it goes over
//      if (sessionMinutes - duration < 30 && sessionMinutes - duration > 0) {
//        duration = Math.round(sessionMinutes / 5) * 5; // Take remainder & round to nearest 5
//      }
//
//      console.log(`   ✅ Adding session: ${duration} minutes (split into ${numSplits} parts)`);
//      sessionList.push(duration);
//      sessionMinutes -= duration;
//
//      console.log(`   🔄 Remaining for ${section.name}: ${sessionMinutes.toFixed(2)} minutes`);
//      console.log(`   🗺️ Current session map:`, JSON.parse(JSON.stringify(sessionMap))); // Log deep copy
//    }
//
//    // Assign the completed session list to sessionMap
//    sessionMap[section.name] = sessionList;
//
//    console.log(`   ✅ Final sessions for ${section.name}:`, sessionMap[section.name]);
//  });
//
//  console.log("\n📌 Final session map before ordering:", JSON.parse(JSON.stringify(sessionMap)));
//
//  // Step 4: Define fixed order
//  const order = ["Play Games", "Analyze Games", "Calculation Puzzles", "Endgames", "Puzzles", "Openings", "Blindfold Puzzles"];
//
//  // Step 5: Distribute sessions following the order, repeating as needed
//  let sessionQueue = [];
//  let sessionIndexes = {}; // Track session progress per category
//
//  let totalAssignedMinutes = 0;
//
//  while (true) {
//    let foundSession = false;
//
//    // Iterate through the fixed order, repeating if necessary
//    for (let i = 0; i < order.length; i++) {
//      let sessionName = order[i];
//
//      if (sessionMap[sessionName] && sessionMap[sessionName].length > 0) {
//        // Get next session without modifying the array
//        let sessionIndex = sessionIndexes[sessionName] || 0;
//        if (sessionIndex < sessionMap[sessionName].length) {
//          let sessionMinutes = sessionMap[sessionName][sessionIndex];
//
//          sessionQueue.push({ name: sessionName, duration: sessionMinutes });
//          totalAssignedMinutes += sessionMinutes;
//          sessionIndexes[sessionName] = sessionIndex + 1; // Move to next session in category
//
//          console.log(`📍 Placing ${sessionName}: ${sessionMinutes} minutes`);
//          console.log(`   🗺️ Updated session map after placement:`, JSON.parse(JSON.stringify(sessionMap)));
//
//          foundSession = true;
//        }
//      }
//    }
//
//    // If no session was placed, break to prevent infinite loop
//    if (!foundSession) break;
//  }
//
//  console.log("✅ Final ordered study blocks:", sessionQueue);
//  return sessionQueue;
//};

const generateStudyBlocks = (studyPlan) => {
  console.log("Generating study blocks...");
  let blocks = [];

  // Step 1: Convert percentages into normalized ratios
  let totalPercentage = studyPlan.reduce((sum, section) => sum + section.percentage, 0);
  let ratios = studyPlan.map((section) => ({
    ...section,
    ratio: section.percentage / totalPercentage, // Normalize ratio
  }));

  console.log("Normalized study plan ratios:", ratios);

  // Step 2: Find the smallest ratio and scale it to 20 minutes
  let minRatio = Math.min(...ratios.map((s) => s.ratio));
  let baseTime = 20 / minRatio; // Base total rotation time

  console.log(`Base rotation time calculated: ${baseTime.toFixed(2)} minutes`);

  // Step 3: Generate sessions and store in a list
  let sessionMap = {};
  ratios.forEach((section) => {
    let sessionMinutes = section.ratio * baseTime;
    let sessionList = []; // Temporary list for appending sessions

    console.log(`\n🔹 Processing: ${section.name}`);
    console.log(`   ➡ Total Minutes for ${section.name}: ${sessionMinutes.toFixed(2)} (before splitting)`);

    while (sessionMinutes > 0) {
      let numSplits = Math.ceil(sessionMinutes / 40); // Decide number of splits
      let duration = Math.round((sessionMinutes / numSplits) / 5) * 5; // Round to nearest 5

      if (duration < 20) duration = 20; // Ensure at least 30 minutes

      // Adjust last session if it goes over
      if (sessionMinutes - duration < 20 && sessionMinutes - duration > 0) {
        duration = Math.round(sessionMinutes / 5) * 5; // Take remainder & round to nearest 5
      }

      console.log(`   ✅ Adding session: ${duration} minutes (split into ${numSplits} parts)`);

      // Ensure each session gets the corresponding note from the study plan
      sessionList.push({
        name: section.name,
        duration,
        note: section.note || "",
        path: section.path || "/train", // fallback just in case
      });
      
      sessionMinutes -= duration;

      console.log(`   🔄 Remaining for ${section.name}: ${sessionMinutes.toFixed(2)} minutes`);
      console.log(`   🗺️ Current session map:`, JSON.parse(JSON.stringify(sessionMap))); // Log deep copy
    }

    // Assign the completed session list to sessionMap
    sessionMap[section.name] = sessionList;

    console.log(`   ✅ Final sessions for ${section.name}:`, sessionMap[section.name]);
  });

  console.log("\n📌 Final session map before ordering:", JSON.parse(JSON.stringify(sessionMap)));

  // Step 4: Define fixed order
  const order = ["Play Games", "Analyze Games", "Calculation Puzzles", "Endgames", "Puzzles", "Openings", "Blindfold Puzzles"];

  // Step 5: Distribute sessions following the order, repeating as needed
  let sessionQueue = [];
  let sessionIndexes = {}; // Track session progress per category

  let totalAssignedMinutes = 0;

  while (true) {
    let foundSession = false;

    // Iterate through the fixed order, repeating if necessary
    for (let i = 0; i < order.length; i++) {
      let sessionName = order[i];

      if (sessionMap[sessionName] && sessionMap[sessionName].length > 0) {
        // Get next session without modifying the array
        let sessionIndex = sessionIndexes[sessionName] || 0;
        if (sessionIndex < sessionMap[sessionName].length) {
          let session = sessionMap[sessionName][sessionIndex];

          sessionQueue.push(session);
          totalAssignedMinutes += session.duration;
          sessionIndexes[sessionName] = sessionIndex + 1; // Move to next session in category

          console.log(`📍 Placing ${sessionName}: ${session.duration} minutes`);
          console.log(`   🗺️ Updated session map after placement:`, JSON.parse(JSON.stringify(sessionMap)));

          foundSession = true;
        }
      }
    }

    // If no session was placed, break to prevent infinite loop
    if (!foundSession) break;
  }

  console.log("✅ Final ordered study blocks:", sessionQueue);
  return sessionQueue;
};

const saveStudyPlan = async () => {
  if (!user?.uid) return;

  const totalPercentage = studyPlan.reduce((sum, item) => sum + item.percentage, 0);
  if (totalPercentage !== MAX_PERCENTAGE) {
    alert("Total percentage must equal 100% before saving.");
    return;
  }

  // Generate valid study blocks
  const studyBlocks = generateStudyBlocks(studyPlan).map((block) => ({
    ...block,
    note: block.note || "", // Ensure a default empty string if no note is provided
  }));

  // Save to Firestore
  const planRef = doc(collection(db, "users", user.uid, "train"), "studyPlan");
  await setDoc(planRef, { studyPlan, studyBlocks, totalHours }, { merge: true });

  // Update local state
  setStudyPlan(studyPlan);
  setStudyBlocks(studyBlocks);
  setEditing(false);
};

  return (
    <div className="edit-container">
      <EnableScrollOnPage />
      <JoyrideWrapper
        steps={EditStudyPlanRide}
        run={isTourOpen}
        onFinish={() => setIsTourOpen(false)} // Close tour when finished
      />
      <h2 className="edit-title">Edit Study Plan</h2>

      <label className="edit-label">
        <strong>Total Study Time Per Week (hours):</strong>
        <input
          type="number"
          value={totalHours}
          onChange={(e) => setTotalHours(parseFloat(e.target.value) || 0)}
          className="edit-input"
          min="1"
        />
      </label>

      {/* Visual Representation of Total Percentage */}
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{
            width: `${Math.min(totalPercentage, 100)}%`,
            background: totalPercentage === MAX_PERCENTAGE ? "#4CAF50" : "#E3B866",
          }}
        ></div>
      </div>
      <p className={`percentage-text ${totalPercentage === MAX_PERCENTAGE ? "valid" : "invalid"}`}>
        Total: {totalPercentage}%
      </p>

      {/* Study Plan Sections */}
      {studyPlan.map((item, idx) => (
        <div key={idx} className="study-plan-section">
          <select
            value={item.path}
            onChange={(e) => updateSection(idx, "path", e.target.value)}
            className="edit-select"
          >
            <option value="/playgames">Play Games</option>
            <option value="/gameanalysis">Analyze Games</option>
            <option value="/endgames">Endgames</option>
            <option value="/puzzles">Puzzles</option>
            <option value="/calculationpuzzles">Calculation Puzzles</option>
            <option value="/blindfoldpuzzles">Blindfold Puzzles</option>
            <option value="/openingtrainer">Openings</option>
          </select>

          <input
            type="number"
            value={item.percentage}
            onChange={(e) => {
              let value = e.target.value;
              if (value === "") {
                updateSection(idx, "percentage", value);
                return;
              }
              updateSection(idx, "percentage", parseInt(value, 10));
            }}
            onBlur={(e) => {
              let value = parseInt(e.target.value, 10);
              if (isNaN(value) || value < 5) value = 5;
              if (value > 100) value = 100;
              updateSection(idx, "percentage", value);
            }}
            className="edit-input percentage-input"
            min="5"
            max="100"
          />
          <span>%</span>

          {/* 📝 Notes Input Field */}
          <textarea
            value={item.note}
            onChange={(e) => updateSection(idx, "note", e.target.value)}
            className="edit-textarea"
            placeholder="Add a note (optional)"
          ></textarea>

          <button onClick={() => removeSection(idx)} className="remove-button">✖</button>
        </div>
      ))}

      <button onClick={() => setEditing(false)} className="subtle-button">
        Cancel
      </button>

      <button onClick={addSection} className="add-button">
        + Add Section
      </button>

      <button onClick={saveStudyPlan} className="average-button save-study-plan">
        Save Plan
      </button>

    </div>
  );
};

export default EditStudyPlan;
