import React from "react";
import { useNavigate } from "react-router-dom";

const PlayGamesOptions = () => {
  const navigate = useNavigate();

  return (
    <div className="play-options-container">
      <h1 className="play-title">Play a Game to Train</h1>
      <p className="play-description">
        Playing full games is one of the best ways to improve your chess. Try to focus during the game,
        and take a moment to reflect afterward. Longer time controls (15+ minutes) usually leads to better learning.
      </p>

      <div className="play-button-group">
        <a
          href="https://lichess.org"
          target="_blank"
          rel="noopener noreferrer"
          className="average-button"
          style={{ textDecoration: "none", color: "white", display: "inline-block", textAlign: "center", fontSize: "20px" }}
        >
          Play on Lichess
        </a>

        <a
          href="https://www.chess.com/play"
          target="_blank"
          rel="noopener noreferrer"
          className="average-button"
          style={{ textDecoration: "none", color: "white", display: "inline-block", textAlign: "center", fontSize: "20px" }}
        >
          Play on Chess.com
        </a>

        <button
          onClick={() => navigate("/play")}
          className="average-button"
          style={{ color: "white", display: "inline-block", textAlign: "center", fontSize: "20px" }}
        >
          Play Here
        </button>
      </div>


      <p className="return-note">
        After playing, come back here and continue your training!
      </p>
    </div>
  );
};

export default PlayGamesOptions;
