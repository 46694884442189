import React from 'react';
import '../../styles/help.css'; // Reusing the same style file for consistency
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const SupportPage = () => {
  return (
    <div className="help-container">
      <EnableScrollOnPage />
      <h1>Support for The Exchange</h1>
      <p>
        If you need help with The Exchange, have suggestions, or encounter any issues, feel free to contact us at:
        <a href="mailto:contact@trainchess.net"> contact@trainchess.net</a>.
      </p>

      {/* Social Media Section */}
      <section className="contact-section">
        <h2>Stay Connected</h2>
        <ul>
          <li>
            <a href="https://www.instagram.com/train.chess" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCO7Guyli8rWF3CqVOIM1nZA" target="_blank" rel="noopener noreferrer">
              YouTube
            </a>
          </li>
          {/* Add other social media platforms if needed */}
        </ul>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>

        <div className="faq-item">
          <h3>What is The Exchange?</h3>
          <p>
            The Exchange is a social platform designed for chess players to share and solve tactical positions.
            Users can post positions from their games, challenge others to find the best move, and discuss strategies with the community.
          </p>
        </div>

        <div className="faq-item">
          <h3>How do I share a chess tactic?</h3>
          <p>
            You can upload a position by selecting a position from your game and following the steps to enter the correct moves and add a title and description. Simple as that!
            Once submitted, other users can engage by trying to find the best move.
          </p>
        </div>

        <div className="faq-item">
          <h3>How do I report a bug or issue?</h3>
          <p>
            If you encounter a problem, please contact us at
            <a href="mailto:contact@trainchess.net"> contact@trainchess.net</a>.
            Be sure to include a detailed description of the issue and any screenshots if applicable.
          </p>
        </div>

        <div className="faq-item">
          <h3>Is The Exchange free to use?</h3>
          <p>
            Yes! The Exchange is free to use, and we are continuously improving the platform to provide the best experience for chess enthusiasts.
          </p>
        </div>
      </section>

      {/* Help Page Link */}
      <section className="further-help">
        <h3>Need More Help?</h3>
        <p>
          Visit our <a href="/help" className="further-help-link">Help Page</a> for additional support and FAQs.
        </p>
      </section>
    </div>
  );
};

export default SupportPage;
