import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { useUser } from "../Integrations/UserContext";

const TrainingContext = createContext();

export const TrainingProvider = ({ children }) => {
  const { user } = useUser();
  const [studyPlan, setStudyPlan] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // New state for pause
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const [studyBlock, setStudyBlock] = useState([]); 
  const [isTrainingLoaded, setIsTrainingLoaded] = useState(false);

  const navigate = useNavigate();

  const chimeAudio = new Audio(process.env.PUBLIC_URL + '/sounds/chime.mp3');

  // Load training progress from Firestore
  useEffect(() => {
    if (user) {
      loadTrainingState();
    }
  }, [user]);

  const loadTrainingState = async () => {
    if (!user?.uid) return;
  
    try {
      // Fetch the study plan from a separate document
      const studyPlanRef = doc(collection(db, "users", user.uid, "train"), "studyPlan");
      const studyPlanSnap = await getDoc(studyPlanRef);
  
      let studyPlanData = [];
      if (studyPlanSnap.exists()) {
        const data = studyPlanSnap.data();
        const plan = data.studyPlan || [];
        const block = data.studyBlocks || [];
      
        setStudyPlan(plan);
        setStudyBlock(block);
        setIsTrainingLoaded(true);
      }
      
  
      // Fetch the training state separately
      const stateRef = doc(collection(db, "users", user.uid, "train"), "trainingState");
      const stateSnap = await getDoc(stateRef);
  
      if (stateSnap.exists()) {
        const data = stateSnap.data();
        const storedTimeLeft = data.timeLeft; // Get stored time left if available
        const currentIdx = data.currentIndex;
        const defaultTime = (studyPlanData[currentIdx]?.duration || 30) * 60; // Default session time
  
        setCurrentIndex(currentIdx);
        setTimeLeft(storedTimeLeft !== undefined ? storedTimeLeft : defaultTime);
        setIsRunning(data.isRunning || false);
  
        // Set pending navigation instead of auto-navigating
        if (data.isRunning && studyPlanData.length > 0) {
          setPendingNavigation(true);
        }
      } else {
        // Initialize trainingState if it doesn't exist
        await setDoc(stateRef, { currentIndex: 0, isRunning: false }, { merge: true });
        setCurrentIndex(0);
        setIsRunning(false);
        setTimeLeft(0);
      }
    } catch (error) {
      console.error("Error loading training state:", error);
    }
  };
  
  // Save training state to Firestore (only every 5 minutes)
  const saveTrainingState = async (
    newTimeLeft = undefined,
    newIndex = undefined,
    isRunningParam = undefined
  ) => {
    if (!user?.uid) return;
  
    const stateToSave = {};
  
    if (typeof newTimeLeft === "number") {
      stateToSave.timeLeft = newTimeLeft;
    }
  
    if (typeof newIndex === "number") {
      stateToSave.currentIndex = newIndex;
    }
  
    if (typeof isRunningParam === "boolean") {
      stateToSave.isRunning = isRunningParam;
    }
  
    const planRef = doc(collection(db, "users", user.uid, "train"), "trainingState");
    await setDoc(planRef, stateToSave, { merge: true });
  };
  
  

  // Timer Effect (runs every second but only saves every 5 minutes)
  useEffect(() => {
    let timer;
    let saveInterval;

    if (isRunning && !isPaused && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            handleTimeEnd();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      // Save to Firestore every 5 minutes (300 seconds)
      saveInterval = setInterval(() => {
        saveTrainingState(timeLeft);
      }, 300000); // 300,000ms = 5 minutes
    }

    return () => {
      clearInterval(timer);
      clearInterval(saveInterval);
    };
  }, [isRunning, isPaused, timeLeft]);

  // Start training session
  const startTraining = async (plan) => {
    if (!plan.length) return;
  
    // setStudyPlan(plan);

    // console.log("plan:", plan);

  
    const sessionTime = (plan[0].duration || 30) * 60;
    setTimeLeft(sessionTime);
    setIsRunning(true);
    setIsPaused(false);
  
    await saveTrainingState(sessionTime, undefined, true);
  
    // ✅ Navigate directly to the first activity
    const targetPath = plan[0].path || "/train";
    console.log(`Navigating to start of training: ${targetPath}`);
    navigate(targetPath);
  };

  // Pause training session
  const pauseTraining = async () => {
    setIsPaused(true);
    await saveTrainingState(timeLeft);
  };

  // Resume training session
  const resumeTraining = async () => {
    setIsPaused(false);
  };

  // Move to the next session
  const handleTimeEnd = async () => {  
    // Calculate how much time was spent in hours
    console.log('Current block time: ', studyBlock[currentIndex]?.duration);
    const sessionMinutes = (studyBlock[currentIndex]?.duration || 30);
  
    if (user?.uid) {
      const planRef = doc(collection(db, "users", user.uid, "train"), "studyPlan");
      const planSnap = await getDoc(planRef);
    
      if (planSnap.exists()) {
        const data = planSnap.data();
        const prev = data.timeSpent || 0;
        const updated = prev + sessionMinutes;
    
        await setDoc(planRef, { timeSpent: updated }, { merge: true });
      }
    }    
  
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    setTimeLeft(0);
    await saveTrainingState(0, newIndex); // ✅ Pass the new index directly
    playChime();
    alert("Training Complete!");
  };
  

  // Stop training completely
  const handleEndTraining = async () => {
    setIsRunning(false);
    setIsPaused(false);
    setTimeLeft(0);
    await saveTrainingState(0, undefined, false); // Reset everything
    navigate("/train");
  };
  
  // Quit training without advancing to the next index
  const handleQuitTraining = async () => {
    const confirmQuit = window.confirm("Are you sure you want to quit your training session?");
    if (!confirmQuit) return;
  
    setIsRunning(false);
    setIsPaused(false);
    setTimeLeft(0);
    await saveTrainingState(0, currentIndex, false); // Keep currentIndex unchanged
    navigate("/train");
  };
  

  // Function to continue training (navigate and reset pendingNavigation)
  const continueTraining = () => {
    if (!isTrainingLoaded) {
      console.warn("Training data is still loading...");
      return;
    }
  
    if (studyBlock.length > 0) {
      const targetPath = studyBlock[currentIndex]?.path || "/train";
      console.log(`Navigating to: ${targetPath}`);
      navigate(targetPath);
      setPendingNavigation(false);
    } else {
      console.warn("Study block is empty. Cannot continue training.");
    }
  };
  


const playChime = () => {
  chimeAudio.currentTime = 0; // Reset time so it plays every time
  chimeAudio.play().catch((error) => console.error("Audio playback failed:", error));
};

  return (
    <TrainingContext.Provider value={{
      studyPlan,
      studyBlock,
      currentIndex,
      timeLeft,
      isRunning,
      startTraining,
      pauseTraining, // Provide pause function
      resumeTraining, // Provide resume function
      handleTimeEnd,
      handleEndTraining,
      continueTraining,
      pendingNavigation,
      handleQuitTraining,
    }}>
      {children}
    </TrainingContext.Provider>
  );
};

export default TrainingContext;