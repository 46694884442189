import React from "react";
import EnableScrollOnPage from "../../Integrations/EnableScroll";

const TerminateAccount = () => {
  return (
    <div className="terminate-page">
        <EnableScrollOnPage />
      <h1>How to Delete Your Account</h1>
      <p>If you'd like to remove your data from The Exchange, follow these steps:</p>

      <div className="step-section">
        <h2>Step 1: Go to Settings</h2>
        <img src="/help/terminate1.png" alt="Settings screen" className="screenshot-small" />
        <p>Tap the ⚙️ gear icon in the top-right corner of the app to access settings.</p>
      </div>

      <div className="step-section">
        <h2>Step 2: Select "Terminate Account"</h2>
        <img src="/help/terminate2.png" alt="Delete Account screen" className="screenshot-small" />
        <p>Tap <strong>Terminate Account</strong>. You’ll be asked to confirm.</p>
      </div>

      <div className="note">
        <p>
          This action is permanent and will delete all of your shared positions and data.
          If you need help, please reach out via <a href="/contact">our contact page</a>.
        </p>
      </div>
    </div>
  );
};

export default TerminateAccount;
