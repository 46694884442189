import React from "react";
import "../../styles/TheExchange.css"; // Create this for custom styles if needed

const TheExchangeLandingPage = () => {
  return (
    <div className="exchange-page">
      <header className="exchange-hero">
        <h1 className="exchange-title">The Exchange</h1>
        <p className="exchange-tagline">
          A chess social app for sharing tactical positions and learning from others.
        </p>
      </header>

      <section className="download-section">
        <div className="platform-card">
          <h2>Download on iPhone</h2>
          <div className="qr-placeholder">
            <div className="help-image">
              <img src="/help/ios_qr.png" alt="iPhone QR Code for The Exchange" />
            </div>
          </div>
          <a
            className="average-button no-underline"
            href="https://apps.apple.com/us/app/the-exchange-chess/id6742661001"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download on the App Store
          </a>
        </div>

        <div className="platform-card">
          <h2>Download on Android</h2>
          <div className="qr-placeholder">
            {/* Replace with actual QR later */}
            <p>QR Code Placeholder</p>
          </div>
          <button className="average-button disabled">
            Coming Soon to Google Play
          </button>
        </div>
      </section>

      <section className="feature-highlight">
        <h2>What is The Exchange?</h2>
        <p>
          The Exchange is like a social media for chess—players. Post positions from real games,
          try to solve each other’s tactics, and comment with ideas and analysis.
        </p>
        <ul>
          <li>Post your brilliant moves</li>
          <li>Solve your friends' shared positions</li>
          <li>Practice your tactical pattern recognition with puzzles from real games</li>
        </ul>
      </section>

      <footer className="exchange-footer">
        <p>© {new Date().getFullYear()} The Exchange • TrainChess.net</p>
      </footer>
    </div>
  );
};

export default TheExchangeLandingPage;
