import React, { useState, useEffect, useContext } from "react";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { useUser } from "../Integrations/UserContext";
import EditStudyPlan from "../Integrations/EditStudyPlan";
import TrainingContext from "../Integrations/TrainingContext";
import EnableScrollOnPage from "../Integrations/EnableScroll";
import '../styles/train.css'; // Ensure the path matches your folder structure
import JoyrideWrapper from '../Integrations/JoyrideWrapper';
import { TrainRide } from '../Integrations/Joyrides';

const Train = () => {
  const { user } = useUser();
  const { startTraining, isRunning, currentIndex } = useContext(TrainingContext);
  const [studyPlan, setStudyPlan] = useState([]);
  const [totalHours, setTotalHours] = useState(5);
  const [editing, setEditing] = useState(false);
  const [studyBlocks, setStudyBlocks] = useState([]);
  const [timeSpent, setTimeSpent] = useState(0);
  const [weekProgress, setWeekProgress] = useState(0);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    if (user) loadStudyPlan();
    calculateWeekProgress();
    setShowLoginMessage(!user); // Show message if user is not logged in
  }, [user]);

  const handleCloseMessage = () => {
    setShowLoginMessage(false); // Close login message
};

  useEffect(() => {
      const checkIfFirstVisit = async () => {
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
              const data = userDoc.data();
              if (!data.hasSeenTrainRide) {
                  setIsTourOpen(true); // Start tour if it’s the user’s first visit
                  await setDoc(userRef, { hasSeenTrainRide: true }, { merge: true });
              }
          }
      };

      if (user) {
          checkIfFirstVisit();
      }
  }, [user]);

  const calculateWeekProgress = () => {
    const now = new Date();
    const startOfWeek = new Date(now);
    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(now.getDate() - now.getDay()); // Set to Sunday start

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 7); // End of the week

    const progress = ((now - startOfWeek) / (endOfWeek - startOfWeek)) * 100;
    setWeekProgress(progress);
  };

  const loadStudyPlan = async () => {
    if (!user?.uid) return;

    const planRef = doc(collection(db, "users", user.uid, "train"), "studyPlan");
    const planSnap = await getDoc(planRef);

    if (planSnap.exists()) {
      const data = planSnap.data();
      const lastReset = data.lastReset || 0;

      const now = new Date();
      const startOfWeek = new Date(now);
      startOfWeek.setHours(0, 0, 0, 0);
      startOfWeek.setDate(now.getDate() - now.getDay()); // Set to Sunday start

      let newTimeSpent = data.timeSpent || 0;

      // Check if the last reset was before the start of the current week
      if (lastReset < startOfWeek.getTime()) {
        newTimeSpent = 0; // Reset time spent
        await setDoc(planRef, { timeSpent: 0, lastReset: Date.now() }, { merge: true });
      }

      setStudyPlan(data.studyPlan || []);
      setTotalHours(data.totalHours || 5);
      setStudyBlocks(data.studyBlocks || []);
      setTimeSpent(newTimeSpent);

      // Ensure currentIndex is within bounds
      const currentIndex = data.currentIndex || 0;
      if (currentIndex >= (data.studyBlocks || []).length) {
        await setDoc(planRef, { currentIndex: 0 }, { merge: true });
      }
    } else {
      setEditing(true);
    }
  };

  return editing ? (
    <EditStudyPlan
      setStudyPlan={setStudyPlan}
      setStudyBlocks={setStudyBlocks}
      setTotalHours={setTotalHours}
      totalHours={totalHours}
      setEditing={setEditing}
    />
  ) : (
    <div className="train-container">
      <EnableScrollOnPage />
      <JoyrideWrapper
        steps={TrainRide}
        run={isTourOpen}
        onFinish={() => setIsTourOpen(false)} // Close tour when finished
      />
      {showLoginMessage && (
          <div className="login-message">
              <button className="close-button" onClick={handleCloseMessage}>×</button>
              <p><a href="/login">Log in</a> to create a study plan and start training.</p>
          </div>
      )}
      <h2 className="train-title">Your Chess Study Plan</h2>

      <p className="train-text">
        <strong>Time Spent This Week:</strong> {Math.round(timeSpent)} minutes /{" "}
        {totalHours * 60} minutes
      </p>

      {/* Progress Bar */}
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{
            width: `${Math.min((timeSpent / (totalHours * 60)) * 100, 100)}%`,
          }}
        ></div>
      </div>

    {/* Week Progress Bar */}
    <p className="train-text">
      <strong>Week Progress:</strong> {weekProgress.toFixed(1)}%
    </p>
    <div className="progress-bar-container">
      <div
        className="progress-bar"
        style={{
          width: `${weekProgress}%`,
          backgroundColor: "rgba(90, 90, 90)", // Orange to contrast
        }}
      ></div>
    </div>

    {/* Next Up Section */}
    <div className="next-up-box">
      <h3 className="train-subtitle">Next Up:</h3>
      {studyBlocks.length > 0 ? (
        <>
          <p className="next-up">
            <strong>{studyBlocks[currentIndex]?.name}</strong> -{" "}
            {studyBlocks[currentIndex]?.duration} min
          </p>
          {/* Display the note if it exists */}
          {studyBlocks[currentIndex]?.note && (
            <p className="next-up-note">
              {studyBlocks[currentIndex]?.note}
            </p>
          )}
        </>
      ) : (
        <p className="next-up">No study plan generated yet.</p>
      )}

      <button
        onClick={() => {
          if (studyBlocks.length > 0) {
            startTraining([{ ...studyBlocks[currentIndex], timeLeft: studyBlocks[currentIndex].duration * 60 }]);
          }
        }}
        disabled={isRunning || studyBlocks.length === 0}
        className={`train-button ${isRunning ? "disabled" : ""}`}
      >
        {isRunning ? "Training in Progress" : "TRAIN"}
      </button>
    </div>

      <button onClick={() => setEditing(true)} className="edit-button">
        Edit Study Plan
      </button>

      {/* Display Study Plan Below */}
      <div className="study-plan-container">
        <h3>Your Study Plan</h3>
        <ul className="study-plan-list">
          {studyPlan.map((item, idx) => (
              <li key={idx} className="study-plan-item">
                <div className="study-plan-text">
                  <strong>{item.name}</strong>
                  {item.note && item.note.trim() !== "" && (
                    <span className="study-plan-note"> - {item.note}</span>
                  )}
                </div>
                <span className="study-plan-percentage">{item.percentage}%</span>
              </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default Train;
